import { StepConnector, stepConnectorClasses } from '@mui/material'
import { styled } from '@mui/system'
import { AdasBox, AdasStep, AdasStepLabel, AdasStepper } from '@components/wrapper-components'
import { SuccessIcon } from '../../icons/src/lib'
const LineConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: '50%',
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1976d2'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1976d2'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#1976d2',
    borderTopWidth: 2,
    borderRadius: 1
  }
}))

const AdasStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: ownerState.error ? '#d32f2f' : ownerState.active || ownerState.completed ? '#1976d2' : '#eaeaf0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(ownerState.completed && {
    color: '#1976d2'
  }),
  '& .AdasStepIcon-completedIcon': {
    color: ownerState.error ? '#d32f2f' : '#1976d2',
    zIndex: 1,
    width: 50,
    height: 50
  },
  '& .AdasStepIcon-circle': {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: `2px solid ${ownerState.error ? '#d32f2f' : '#1976d2'}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ownerState.active ? (ownerState.error ? '#d32f2f' : '#1976d2') : 'transparent',
    color: ownerState.active ? '#fff' : ownerState.error ? '#d32f2f' : '#1976d2'
  }
}))

function AdasStepIcon(props) {
  const { active, completed, error, className, icon } = props
  return (
    <AdasStepIconRoot ownerState={{ active, completed, error }} className={className}>
      {completed ? (
        <AdasBox>
          <SuccessIcon className='AdasStepIcon-completedIcon' />
        </AdasBox>
      ) : (
        <AdasBox className='AdasStepIcon-circle'>{icon}</AdasBox>
      )}
    </AdasStepIconRoot>
  )
}

function AdasCustomStepper({ activeStep, steps, errorSteps = [] }) {
  return (
    <AdasStepper alternativeLabel sx={{ width: '100%' }} activeStep={activeStep} connector={<LineConnector />}>
      {steps.map((label, index) => (
        <AdasStep key={index}>
          <AdasStepLabel StepIconComponent={(props) => <AdasStepIcon {...props} icon={index + 1} error={errorSteps.includes(index)} />} />
        </AdasStep>
      ))}
    </AdasStepper>
  )
}

export default AdasCustomStepper
