import PropTypes from 'prop-types'
import { useCallback, useRef, useState } from 'react'

import { useCenterStore } from '@caradasstore/CenterStore'
import { ToggleActive } from '@components/buttons'
import {
  AdasAlert,
  AdasBox,
  AdasButton,
  AdasCard,
  AdasFormControl,
  AdasFormLabel,
  AdasRadioGroup,
  AdasSearchMenu,
  AdasTextField
} from '@components/wrapper-components'
import { UsaStates } from 'usa-states'
import { updateClient } from '../../../api/api'
import { phoneNumberFormat, validations, zipCodeInput } from '../../../utils/utils'
import { LABEL_DISPLAY, MAX_CLIENT_NOTES_LENGTH, TEXT_CONSTANTS } from '../../../constants'
import { Logger } from '../../../logger'

export const ClientForm = (props) => {
  const { clientData = { is_active: true, type: 'FLEET' }, close, onSaveSuccess, existingClients = [] } = props
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const [client, setClient] = useState(clientData ? clientData : {})
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    address1: false,
    city: false,
    state: false,
    zip: false
  })
  const [valid, setValid] = useState({
    name: validations.name(clientData.name),
    email: validations.email(clientData.email),
    phone: validations.phone(clientData.phone),
    address1: validations.address1(clientData.address1),
    city: validations.city(clientData.city),
    state: validations.state(clientData.state),
    zip: validations.zip(clientData.zip)
  })
  const [duplicateName, setDuplicateName] = useState(false)
  const emailInputRef = useRef()
  const usStates = new UsaStates()

  const handleInputChange = useCallback((e) => {
    const name = e.target.name
    if (name === 'name') {
      setDuplicateName(false)
    }
    let value = e.target.value
    if (name === 'zip') {
      value = zipCodeInput(value)
    } else if (name === 'email') {
      value = value.replace(/\s/g, '')
    } else if (name === 'vehicle_count') {
      value = value.replace(/\D/g, '')
    }

    setClient((prev) => {
      const client = {
        ...prev,
        [name]: value
      }
      return client
    })
    setTouched((prev) => {
      return {
        ...prev,
        [name]: true
      }
    })
    setValid((prev) => {
      const valid = {
        ...prev
      }
      if (validations[name]) {
        valid[name] = validations[name](value)
      }
      return valid
    })
  }, [])

  const handleToggleActive = useCallback((e) => {
    const value = e.target.value
    setClient((prev) => {
      const client = {
        ...prev,
        is_active: value === 'active'
      }
      return client
    })
  }, [])

  const trimSpaces = (data) => {
    const trimmedData = {}
    for (const key in data) {
      if (typeof data[key] === 'string') {
        trimmedData[key] = data[key].trim()
      } else {
        trimmedData[key] = data[key]
      }
    }
    return trimmedData
  }

  const handleSaveClient = useCallback(async () => {
    const data = trimSpaces({
      ...client,
      calibration_center_id: centerDetail.id,
      country: 'USA'
    })

    if (existingClients.includes(client.name)) {
      setValid({ ...valid, name: false })
      setDuplicateName(true)
      return
    }

    try {
      const response = await updateClient({ data })
      if (response && response.status === 200) {
        setClient(response.data)
        close()
        onSaveSuccess(data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'clientForm/handleSaveClient', data } })
    }
  }, [centerDetail.id, client, close, existingClients, onSaveSuccess, valid])

  const isDisabled = !valid.name || !valid.email || !valid.phone || !valid.address1 || !valid.city || !valid.state || !valid.zip

  return (
    <AdasCard className='adas-card'>
      <AdasTextField
        onChange={handleInputChange}
        name='name'
        sx={{ marginBottom: '20px' }}
        id='name'
        label='Name *'
        variant='outlined'
        helperText={duplicateName ? 'Client already exists, please review Client list' : null}
        value={client && client.name ? client.name : ''}
        error={touched.name && !valid.name}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 40
        }}
      />
      <AdasFormControl sx={{ padding: '20px 0px' }}>
        <AdasFormLabel id='type-group'>Type</AdasFormLabel>
        <AdasRadioGroup
          row
          options={[
            { label: 'Fleet', value: 'FLEET' },
            { label: 'Customer Pay', value: 'CUSTOMERPAY' }
          ]}
          aria-labelledby='type-group'
          name='type'
          value={client && client.type}
          onChange={handleInputChange}
        />
      </AdasFormControl>
      <AdasTextField
        onChange={handleInputChange}
        name='vehicle_count'
        sx={{ marginBottom: '20px' }}
        id='vehicle-count'
        label={LABEL_DISPLAY.POTENTIAL_MONTHLY_RO_COUNT_LABEL}
        variant='outlined'
        value={client && client.vehicle_count}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 3,
          inputMode: 'numeric',
          pattern: '\\d*'
        }}
      />
      <ToggleActive toggle={client.is_active} handleToggleActive={handleToggleActive} />
      <AdasTextField
        onChange={handleInputChange}
        name='phone'
        sx={{ marginBottom: '20px' }}
        id='phone'
        label='Phone *'
        variant='outlined'
        value={client && client.phone ? phoneNumberFormat(client.phone) : ''}
        error={touched.phone && !valid.phone}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 14
        }}
      />
      <AdasTextField
        type='text'
        onChange={handleInputChange}
        inputRef={emailInputRef}
        name='email'
        sx={{ marginBottom: '20px' }}
        id='email'
        label='Email *'
        variant='outlined'
        value={client && client.email ? client.email : ''}
        error={touched.email && !valid.email}
        helperText={touched.email && !valid.email ? TEXT_CONSTANTS.ENTER_VALID_EMAIL : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 50
        }}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='address1'
        sx={{ marginBottom: '20px' }}
        id='address'
        label='Address *'
        variant='outlined'
        value={client && client.address1 ? client.address1 : ''}
        error={touched.address1 && !valid.address1}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 40
        }}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='city'
        sx={{ marginBottom: '20px' }}
        id='city'
        label='City *'
        variant='outlined'
        value={client && client.city ? client.city : ''}
        error={touched.city && !valid.city}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 28
        }}
      />
      <AdasSearchMenu
        fullWidth
        id='select-state'
        select
        label='State *'
        variant='outlined'
        onChange={handleInputChange}
        sx={{ marginBottom: '20px' }}
        name='state'
        value={client && client.state ? client.state : ''}
        error={touched.state && !valid.state}
        options={usStates?.states?.map((state) => ({
          value: state.abbreviation,
          name: state.name
        }))}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='zip'
        sx={{ marginBottom: '20px' }}
        id='zip'
        label='Zip *'
        variant='outlined'
        value={client && client.zip ? client.zip : ''}
        error={touched.zip && !valid.zip}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 5
        }}
      />
      <AdasSearchMenu
        onChange={handleInputChange}
        name='country'
        select
        sx={{ marginBottom: '20px' }}
        id='country'
        label='Country'
        variant='outlined'
        value={client && client.country ? client.country : 'USA'}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 25
        }}
        options={[{ value: 'USA', name: 'United States of America' }]}
      />
      <AdasTextField
        fullWidth
        name='notes'
        label='Additional Notes'
        sx={{ marginBottom: '20px' }}
        value={client.notes}
        onChange={handleInputChange}
        inputProps={{ maxLength: MAX_CLIENT_NOTES_LENGTH }}
        helperText={`Maximum ${MAX_CLIENT_NOTES_LENGTH} characters (${MAX_CLIENT_NOTES_LENGTH - (client.notes?.length || 0)} remaining).`}
        multiline
        rows={4}
      />
      {duplicateName && <AdasAlert severity='error'>Client already exists, please review Client list</AdasAlert>}
      <AdasBox sx={{ margin: '20px 0px' }}>
        <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={close}>
          Cancel
        </AdasButton>
        <AdasButton
          type='submit'
          disabled={isDisabled}
          sx={{ float: 'right' }}
          variant='contained'
          aria-label='save'
          color='primary'
          isDebounceEnabled={true}
          onClick={handleSaveClient}
        >
          Save
        </AdasButton>
      </AdasBox>
    </AdasCard>
  )
}

ClientForm.propTypes = {
  clientData: PropTypes.object,
  close: PropTypes.func,
  onSaveSuccess: PropTypes.func,
  existingClients: PropTypes.array
}
