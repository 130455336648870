import SearchIcon from '@mui/icons-material/Search'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CircularProgress, Divider } from '@mui/material'
import { DownloadOutlined, EmailOutlined } from '@mui/icons-material'
import { getWorkorderEmailPreview, getWorkOrderList, getWorkorderPdf } from '../../../api/api'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore } from '@caradasstore/UserStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../../logger'
import { ItemPerPageSelector } from '@components/common'
import { QueueItem } from '@components/queue-item'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasContainer,
  AdasPagination,
  AdasPaper,
  AdasStack,
  AdasTextField
} from '@components/wrapper-components'
import { REDIRECT_URLS, getWorkOrderSummaryUrl } from '../../../constants'
import { scrollPageTop, useScrollTop } from '../../../utils/useScrollTop'
import { WorkFlowTypesEnum } from '../../../enums/enums'
import { EmailForm } from '@components/email'
import { useDeviceQueries } from '@hooks/useDeviceQueries'
export const WorkOrderPage = () => {
  useScrollTop()
  const location = useLocation()
  const [workOrderList, setWorkOrderList] = useState([])
  const [isAssignedToMe, setIsAssignedToMe] = useState(location?.state?.isAssignedToMe || '')
  const [searchInput, setSearchInput] = useState('')
  const [pdfLoader, setPdfLoader] = useState(false)
  const [workorderPdf, setWorkorderPdf] = useState()
  const [selectedWorkorder, setSelectedWorkorder] = useState()
  const [selectedWorkorderReferenceNumber, setSelectedWorkorderReferenceNumber] = useState()
  const [isEmail, setIsEmail] = useState(false)
  const [emailContacts, setEmailContacts] = useState({})
  const [pageParams, setPageParams] = useState({
    pageNo: 0,
    totalPages: 0,
    pageSize: 25,
    status: 'OPEN',
    search: ''
  })
  const user = useUserStore((store) => store.currentUser)
  const ccId = useCenterStore((store) => store.ccId)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const history = useHistory()
  const { isMobile } = useDeviceQueries()

  const redirectToWorkOrderSummary = (data) => {
    history.push(getWorkOrderSummaryUrl(data?.reference_number), { isAssignedToMe })
  }

  const handleSearchChange = useCallback((e) => {
    setSearchInput(e.target.value)
  }, [])

  const viewPdfHandler = async () => {
    try {
      setPdfLoader(true)
      const workorderPdfResponse = await getWorkorderPdf(selectedWorkorder)
      if (workorderPdfResponse.status === 200) {
        const fileValue = new Blob([workorderPdfResponse.data], { type: 'application/pdf' }, `wo_${selectedWorkorderReferenceNumber}.pdf`)
        fileValue.id = selectedWorkorder
        const fileURL = URL.createObjectURL(fileValue)
        const pdfWindow = window.open()
        if (pdfWindow === null) {
          alert('Pop-up was blocked by your browser! Please allow pop-ups for caradasplus.com to view Quote.')
        } else {
          pdfWindow.location.href = fileURL
        }
      }
    } catch (error) {
      Logger.error({ message: error, payload: { workorderId: selectedWorkorder, path: 'WorkOrderPage/viewPdfHandler' } })
    } finally {
      setPdfLoader(false)
    }
  }

  const emailWorkorderHandler = useCallback(async () => {
    // setLoading(true)
    try {
      const emailContactResponse = await getWorkorderEmailPreview(selectedWorkorder)
      if (emailContactResponse.status === 200) {
        setIsEmail(true)
        setEmailContacts(emailContactResponse.data)
      }
      const workorderPdfResponse = await getWorkorderPdf(selectedWorkorder)
      if (workorderPdfResponse.status === 200) {
        const fileValue = new File([workorderPdfResponse.data], `workorder_${selectedWorkorderReferenceNumber}.pdf`)
        fileValue.id = selectedWorkorder
        setWorkorderPdf(fileValue)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { WorkorderId: selectedWorkorder, path: 'WorkorderPage/emailWorkorderHandler' } })
    }
  }, [selectedWorkorder, selectedWorkorderReferenceNumber])

  useEffect(() => {
    const performSearch = () => {
      setPageParams((prev) => {
        const updated = {
          ...prev,
          search: searchInput
        }
        return updated
      })
    }

    // Set a delay to perform the search 500ms after typing stops
    const timeoutId = setTimeout(performSearch, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const toggleHandler = (e) => {
    if (e.target.value === 'all' && isAssignedToMe) {
      setIsAssignedToMe(false)
      history.replace({ ...location, state: { isAssignedToMe: false } })
    }

    if (e.target.value === 'me' && !isAssignedToMe) {
      setIsAssignedToMe(true)
      history.replace({ ...location, state: { isAssignedToMe: true } })
    }
  }

  const paginationHandler = (event, page) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageNo: page - 1
      }
      return updated
    })
  }

  const pageSizeHandler = (event) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageSize: event.target.value,
        pageNo: 0
      }
      return updated
    })
  }

  useEffect(() => {
    const fetchWorkOrderList = async () => {
      setLoading(true)
      try {
        const response = await getWorkOrderList({
          pageNo: pageParams.pageNo,
          pageSize: pageParams.pageSize,
          status: pageParams.status,
          ccid: ccId,
          assignedTo: isAssignedToMe ? user.id : '',
          search: pageParams.search
        })

        if (response.status === 200) {
          setWorkOrderList(response.data.workOrders)
          setPageParams((prev) => ({
            ...prev,
            totalPages: response.data.totalPages
          }))
          scrollPageTop()
        }
      } catch (error) {
        Logger.error({ message: error, payload: { pageParams, ccId } })
      } finally {
        setLoading(false)
      }
    }

    fetchWorkOrderList()
  }, [pageParams.pageNo, pageParams.pageSize, pageParams.status, pageParams.search, isAssignedToMe, ccId])

  return (
    <AdasContainer
      maxWidth='lg'
      sx={{
        backgroundColor: isMobile ? 'white' : ''
      }}
    >
      <EmailForm
        workOrderPdf={workorderPdf}
        isOpen={isEmail}
        contacts={emailContacts}
        emailData={{ id: selectedWorkorder }}
        onClose={() => setIsEmail(false)}
      />
      <AdasBox
        sx={{
          overflow: 'auto',
          fontSize: '14px',
          margin: '10px 0px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          paddingTop: { xs: '1rem', md: '0.5rem' }
        }}
      >
        <AdasBox
          sx={{
            marginBottom: { xs: '10px', md: '0' }
          }}
        >
          <AdasButton
            key='2'
            variant='contained'
            aria-label='New Work Order'
            color='primary'
            onClick={() => history.push(REDIRECT_URLS.NEW_WORK_ORDER)}
          >
            New Work Order
          </AdasButton>
        </AdasBox>
        <AdasBox
          sx={{
            display: 'flex',
            justifyContent: { xs: 'flex-start', md: 'flex-end' },
            width: { xs: '100%', md: 'auto' }
          }}
        >
          <AdasButtonGroup>
            <AdasButton value='all' onClick={toggleHandler} variant={isAssignedToMe ? 'outlined' : 'contained'}>
              ALL
            </AdasButton>
            <AdasButton value='me' onClick={toggleHandler} variant={!isAssignedToMe ? 'outlined' : 'contained'}>
              ASSIGNED TO ME
            </AdasButton>
          </AdasButtonGroup>
        </AdasBox>
      </AdasBox>

      <AdasBox>
        <AdasPaper
          elevation={0}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            padding: '.75rem',
            justifyContent: 'space-between'
          }}
        >
          <AdasTextField
            sx={{ borderRadius: '0px', width: '100%', mb: { xs: 1, md: 0.8 } }}
            onChange={handleSearchChange}
            id='search'
            name='search'
            label='Search'
            variant='outlined'
            size='small'
            type={'text'}
            value={searchInput}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              startAdornment: <SearchIcon />,
              placeholder: 'Filter Work Orders'
            }}
          />
          <AdasBox sx={{ display: 'flex', justifyContent: 'flex-end', mt: { xs: 1, md: 0 }, maxHeight: '40px', marginLeft: '.5rem' }}>
            <AdasButton
              sx={{ marginRight: '.5rem', width: '130px' }}
              variant='outlined'
              disabled={!selectedWorkorder}
              isDebounceEnabled={true}
              startIcon={pdfLoader ? <CircularProgress color='inherit' size={20} /> : <DownloadOutlined />}
              onClick={viewPdfHandler}
            >
              View PDF
            </AdasButton>
            <AdasButton
              variant='outlined'
              isDebounceEnabled={true}
              disabled={!selectedWorkorder}
              startIcon={<EmailOutlined />}
              onClick={emailWorkorderHandler}
            >
              Email
            </AdasButton>
          </AdasBox>
        </AdasPaper>
        {isMobile && <Divider />}
      </AdasBox>
      <AdasBox>
        <AdasPaper elevation={0} sx={{ padding: { sm: '1.25rem' }, marginTop: '20px' }}>
          <ul>
            {workOrderList.length > 0 &&
              workOrderList.map((item) => {
                return (
                  <QueueItem
                    itemType={WorkFlowTypesEnum.WORKORDER}
                    isOptionEnabled={true}
                    key={item.id}
                    item={item}
                    isSelected={selectedWorkorder === item.id}
                    onClickItem={redirectToWorkOrderSummary}
                    radioChanged={() => {
                      setSelectedWorkorder(item.id)
                      setSelectedWorkorderReferenceNumber(item.reference_number)
                    }}
                  />
                )
              })}
            {workOrderList.length === 0 && (
              <li>
                No work orders found
                {pageParams.totalPages > 1 && ' on this page. Go to next page to search more work orders.'}
              </li>
            )}
          </ul>
        </AdasPaper>
      </AdasBox>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '20px',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AdasBox
          sx={{
            marginBottom: { xs: '15px', md: '0' }
          }}
        >
          <ItemPerPageSelector itemPerPageList={[25, 50, 100, 200]} pageSizeHandler={pageSizeHandler} pageSize={pageParams.pageSize} />
        </AdasBox>
        <AdasBox>
          <AdasStack spacing={2}>
            <AdasPagination page={pageParams.pageNo + 1} onChange={paginationHandler} count={pageParams.totalPages} shape='rounded' />
          </AdasStack>
        </AdasBox>
      </AdasPaper>
      <AdasBox sx={{ margin: '20px 0px', textAlign: 'end' }}>
        <AdasButton
          key='2'
          variant='contained'
          aria-label='New Work Order'
          color='primary'
          onClick={() => history.push(REDIRECT_URLS.NEW_WORK_ORDER)}
        >
          New Work Order
        </AdasButton>
      </AdasBox>
    </AdasContainer>
  )
}
