import { datadogRum } from '@datadog/browser-rum'
import { APP_ENV } from 'config'
import { DATADOG_CLIENT_TOKEN } from 'config'
import { DATADOG_APP_ID } from 'config'
import { LABEL_DISPLAY } from 'constants'

import { Logger } from '../logger'

export const initializeDataDog = () => {
  try {
    if (!DATADOG_APP_ID || !DATADOG_CLIENT_TOKEN || !APP_ENV) {
      Logger.error({
        message: 'Datadog initialization failed: Missing configuration.',
        payload: { file: 'datadog', method: 'initializeDataDog' }
      })

      return
    }

    datadogRum.init({
      applicationId: DATADOG_APP_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'us5.datadoghq.com',
      service: 'caradas',
      env: APP_ENV,
      version: LABEL_DISPLAY.VERSION_NUMBER,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    })
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'datadog', method: 'initializeDataDog' } })
  }
}
