import { AdasBox, AdasButton, AdasTextField, AdasTypography } from '@components/wrapper-components'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useEffect, useState } from 'react'

export const NoteForm = (props) => {
  const { cancel, submit, allowEdit = true, existingNote, label, noteType, maxLength, txAreaBgColor } = props
  const [isEdit, setIsEdit] = useState(false)
  const [note, setNote] = useState(existingNote ? existingNote : '')

  const handleNoteChange = (e) => {
    setNote(e.target.value)
  }
  const submitHandler = () => {
    submit(note)
    setIsEdit(false)
  }

  const cancelHandler = () => {
    setIsEdit(false)
    cancel()
  }

  useEffect(() => {
    setNote(existingNote || '')
  }, [existingNote, isEdit])

  useEffect(() => {
    setIsEdit(false)
  }, [noteType])
  return existingNote && !isEdit ? (
    <AdasBox
      sx={{
        overflow: 'auto',
        margin: '20px 20px',
        overflowWrap: 'break-word'
      }}
    >
      <AdasTypography variant='subtitle2' sx={{ fontWeight: '600' }}>
        {label}
      </AdasTypography>
      <AdasTextField
        multiline
        rows={4}
        name='noteLabel'
        sx={{
          marginTop: '5px',
          marginBottom: '0px',
          '.MuiInputBase-root': txAreaBgColor ? { backgroundColor: txAreaBgColor } : undefined,
          '.MuiInputBase-root.Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: 'black'
          },
          '.MuiInputBase-input.Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: 'black'
          }
        }}
        id='noteLabel'
        variant='outlined'
        value={existingNote}
        disabled={true}
        fullWidth={true}
      />
      {allowEdit && (
        <AdasBox sx={{ margin: '20px 0px' }}>
          <AdasBox sx={{ float: 'right', overflow: 'auto' }}>
            <AdasButton
              startIcon={<ModeEditOutlineOutlinedIcon />}
              key='2'
              aria-label='Cancel'
              color='primary'
              onClick={() => setIsEdit(true)}
            >
              Edit
            </AdasButton>
          </AdasBox>
        </AdasBox>
      )}
    </AdasBox>
  ) : (
    <AdasBox sx={{ margin: '15px 20px' }}>
      <AdasBox>
        <AdasTextField
          onChange={handleNoteChange}
          multiline
          rows={4}
          name='note'
          sx={{
            marginBottom: '0px',
            '.MuiInputBase-root': txAreaBgColor ? { backgroundColor: txAreaBgColor } : undefined
          }}
          id='note'
          label={label}
          helperText={`Maximum ${maxLength} Characters ${maxLength - note?.length} remaining`}
          variant='outlined'
          value={note}
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            maxLength: maxLength
          }}
        />
      </AdasBox>
      <AdasBox sx={{ margin: '20px 0px', overflow: 'auto' }}>
        <AdasBox sx={{ float: 'right' }}>
          <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={cancelHandler} sx={{ marginRight: '10px' }}>
            Cancel
          </AdasButton>
          <AdasButton disabled={!existingNote && !note} variant='contained' aria-label='save' color='primary' onClick={submitHandler}>
            Save
          </AdasButton>
        </AdasBox>
      </AdasBox>
    </AdasBox>
  )
}
