import { ItemList } from './ItemList'
import { AdasBox, AdasButton, AdasTypography } from '@components/wrapper-components'
import theme from 'theme/theme'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { styled } from '@mui/material/styles'
import { currencyFormatter } from '../../../utils/utils'

import { AdasAccordion, AdasAccordionDetails, AdasAccordionSummary } from '@components/wrapper-components'

const Accordion = styled((props) => (
  <AdasAccordion defaultExpanded={props.defaultExpanded} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:last-child': {
    marginBottom: 10
  },

  '&:before': {
    display: 'none'
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'transparent', // Apply transparent background to AccordionSummary
    borderRadius: '5px' // Set your desired border radius
  }
}))

const AccordionSummary = styled((props) => (
  <AdasAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2) // Add this line to increase the gap
  }
}))
const ExpandableSection = ({
  title,
  total,
  items,
  manageLabel,
  onManage,
  disabled,
  canManage = true,
  defaultExpanded = true,
  onEdit,
  type
}) => {
  return (
    <Accordion sx={{ cursor: 'pointer' }} defaultExpanded={defaultExpanded}>
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <AdasBox display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <AdasTypography variant='body1' sx={{ fontWeight: theme.typography.fontWeightMedium }}>
            {title}
          </AdasTypography>
          <AdasTypography variant='body1' sx={{ fontWeight: theme.typography.fontWeightMedium, marginRight: { md: '2rem' } }}>
            {currencyFormatter(total)}
          </AdasTypography>
        </AdasBox>
      </AccordionSummary>
      <AdasAccordionDetails sx={{ paddingX: { md: '52px' } }}>
        <ItemList itemList={items} name='Packages' onClickEdit={onEdit} type={type} disabled={disabled} />
        {canManage && (
          <AdasBox sx={{ textAlign: 'end' }}>
            <AdasButton startIcon={<LibraryAddCheckOutlinedIcon />} onClick={onManage} disabled={disabled}>
              {manageLabel}
            </AdasButton>
          </AdasBox>
        )}
      </AdasAccordionDetails>
    </Accordion>
  )
}

export default ExpandableSection
