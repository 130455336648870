import { useAuthStore } from '@caradasstore/AuthStore'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore } from '@caradasstore/UserStore'
import { UserForm, UserInfo } from '@components/user'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasContainer,
  AdasDrawer,
  AdasPagination,
  AdasPaper,
  AdasStack,
  AdasTable,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { useCallback, useEffect, useState } from 'react'
import { getAllUserByCcid, getCalibrationCenter } from '../../../api/api'
import { TABLE_HEADER_STYLE, USER_TABLE_HEADERS } from '../../../constants'
import { Logger } from '../../../logger'
import { scrollPageTop } from 'utils/useScrollTop'
import { ItemPerPageSelector } from '@components/common'
import theme from 'theme/theme'

export const UserQueue = () => {
  const [searchInput, setSearchInput] = useState('')
  const [currentUserList, setCurrentUserList] = useState([])
  const [isUserInfo, setIsUserInfo] = useState(false)
  const [isAddUser, setIsAddUser] = useState(false)
  const [userData, setUserData] = useState({})
  const [sort, setSort] = useState('ASC')
  const authCtx = useAuthStore()

  const ccId = useCenterStore((store) => store.ccId)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const currentUser = useUserStore((store) => store.currentUser)
  const setUserList = useCenterStore((store) => store.setUserList)
  const [pageNo, setPageNo] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [isActive, setIsActive] = useState(true)
  const [search, setSearch] = useState('')

  const paginationHandler = (_event, page) => {
    setPageNo(page - 1)
    scrollPageTop()
  }

  const pageSizeHandler = (event) => {
    setPageNo(0)
    setPageSize(event.target.value)
    scrollPageTop()
  }

  const searchInputHandler = useCallback((e) => {
    setSearchInput(e.target.value)
  }, [])

  const clickSortHandler = useCallback(
    (id) => {
      if (id === 'name') setSort(sort === 'ASC' ? 'DESC' : 'ASC')
    },
    [sort]
  )

  const fetchCC = async () => {
    try {
      const response = await getCalibrationCenter({
        ccid: ccId
      })
      setCenterDetail(response.data)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'userQueue', ccId: ccId } })
    }
  }

  useEffect(() => {
    const performSearch = () => {
      setSearch(searchInput)
    }

    // Set a delay to perform the search 500ms after typing stops
    const timeoutId = setTimeout(performSearch, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const fetchUserList = useCallback(async () => {
    const userListResponse = await getAllUserByCcid({
      ccid: centerDetail.id ? centerDetail.id : ccId,
      isActive,
      pageNo,
      pageSize,
      sortBy: 'name',
      sort: sort,
      search
    })

    setTotalPages(userListResponse.data.totalPages)
    if (userListResponse.status === 200) {
      if (currentUser.is_owner || currentUser.is_admin) {
        setCurrentUserList(userListResponse.data.userDisplayDtos.filter((user) => !user.is_admin))
      } else if (currentUser.is_manager) {
        setCurrentUserList(userListResponse.data.userDisplayDtos.filter((user) => !user.is_admin && !user.is_owner))
      }

      setUserList(userListResponse.data.userDisplayDtos.filter((user) => user.is_active)) // only active user should be here since its a global state
    }
  }, [
    ccId,
    pageNo,
    pageSize,
    isActive,
    sort,
    centerDetail?.id,
    currentUser?.is_admin,
    currentUser?.is_manager,
    currentUser?.is_owner,
    search
  ])

  useEffect(() => {
    fetchUserList()
  }, [fetchUserList])

  const userInfoHandler = useCallback((props) => {
    setUserData(props)
    setIsUserInfo(true)
  }, [])

  const onUserAddOrUpdateSuccessHandler = useCallback(() => {
    fetchUserList()
    fetchCC() //Fetch store details since its a global state
  }, [fetchUserList, fetchCC])

  return (
    <AdasContainer maxWidth='lg'>
      <AdasBox
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        {isUserInfo && (
          <AdasDrawer anchor={'right'} open={isUserInfo} onClose={() => setIsUserInfo(false)}>
            <UserInfo
              allowDelete={true}
              updateClientListHandler={onUserAddOrUpdateSuccessHandler}
              userInfo={userData}
              closeDrawer={() => setIsUserInfo(false)}
            />
          </AdasDrawer>
        )}
        {isAddUser && (
          <AdasDrawer anchor={'right'} open={isAddUser} onClose={() => setIsAddUser(false)}>
            <AdasBox className='drawer-title-container'>
              <AdasTypography variant='h6'>User Info</AdasTypography>
              <AdasButton
                buttonType='iconButton'
                sx={{
                  padding: '2px',
                  cursor: 'pointer',
                  color: theme.palette.info.main
                }}
                onClick={() => setIsAddUser(false)}
              >
                <CloseIcon fontSize='small' />
              </AdasButton>
            </AdasBox>
            <UserForm
              isAddUser={true}
              onSaveSuccess={onUserAddOrUpdateSuccessHandler}
              clibrationCenterId={authCtx?.user} //change required
              close={() => setIsAddUser(false)}
              userData={{ is_active: true }}
            />
          </AdasDrawer>
        )}
        <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <AdasButtonGroup
            sx={{ boxShadow: 'none', width: '100%', marginRight: '2rem' }}
            variant='contained'
            aria-label='outlined primary button group'
          >
            <AdasTextField
              sx={{ borderRadius: '0px' }}
              onChange={searchInputHandler}
              id='search'
              name='search'
              label='Search'
              variant='outlined'
              size='small'
              type={'text'}
              value={searchInput}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
                sx: { borderRadius: '0' }
              }}
            />
            <AdasButton onClick={() => setIsAddUser(true)} sx={{ width: '10%', maxHeight: '100px', borderRadius: '0' }} variant='contained'>
              <AddIcon />
            </AdasButton>
          </AdasButtonGroup>
          <AdasButtonGroup>
            <AdasButton onClick={() => setIsActive('')} variant={isActive === '' ? 'contained' : 'outlined'}>
              ALL
            </AdasButton>
            <AdasButton onClick={() => setIsActive(true)} variant={isActive ? 'contained' : 'outlined'}>
              ACTIVE
            </AdasButton>
          </AdasButtonGroup>
        </AdasBox>

        {currentUserList.length > 0 && (
          <AdasTable
            containerProps={{ component: AdasBox }}
            tableProps={{
              sx: {
                '.MuiTableCell-root': {
                  border: 'none',
                  padding: '2px',
                  fontSize: '1rem'
                },
                overflowX: 'scroll',
                marginTop: '20px'
              },
              'aria-label': 'user table'
            }}
            columns={USER_TABLE_HEADERS}
            sortField='name'
            sortDirection={sort}
            onSort={clickSortHandler}
            headerStyle={TABLE_HEADER_STYLE}
            data={currentUserList}
            onSelect={userInfoHandler}
          />
        )}
      </AdasBox>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '20px',
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex'
        }}
      >
        <AdasBox sx={{ width: '20%' }}>
          <ItemPerPageSelector pageSizeHandler={pageSizeHandler} pageSize={pageSize} />
        </AdasBox>
        <AdasBox sx={{ width: '80%', display: 'flex', justifyContent: 'end' }}>
          <AdasStack spacing={2}>
            <AdasPagination onChange={paginationHandler} count={totalPages} shape='rounded' />
          </AdasStack>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
