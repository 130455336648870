import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { CustomItemForm, ItemList } from '@components/custom-item'
import { AdasBox, AdasButton, AdasDrawer, AdasTypography } from '@components/wrapper-components'
import { getCustomItemList } from '../../../api/api'
import { currencyFormatter } from '../../../utils/utils'
import { Logger } from '../../../logger'
import theme from 'theme/theme'

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { styled } from '@mui/material/styles'

import { AdasAccordion, AdasAccordionDetails, AdasAccordionSummary } from '@components/wrapper-components'

const Accordion = styled((props) => (
  <AdasAccordion defaultExpanded={props.defaultExpanded} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:last-child': {
    marginBottom: 10
  },

  '&:before': {
    display: 'none'
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'transparent', // Apply transparent background to AccordionSummary
    borderRadius: '5px' // Set your desired border radius
  }
}))

const AccordionSummary = styled((props) => (
  <AdasAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}))
export const CustomItem = (props) => {
  const {
    existingCustomItemList = [],
    workOrderData,
    onSaveCustomItem,
    customItemTotal,
    disabled = false,
    type,
    defaultExpanded = true
  } = props
  const [customItemList, setCustomItemList] = useState([])
  const [isAddCustomItem, setIsAddCustomItem] = useState(false)
  const [existingCustomItem, setExistingCustomItem] = useState()
  useEffect(() => {
    setCustomItemList(existingCustomItemList)
  }, [existingCustomItemList])
  const addCustomItemHandler = () => {
    setExistingCustomItem()
    setIsAddCustomItem(true)
  }

  const onClickEditHandler = (props) => {
    setExistingCustomItem(props)
    setIsAddCustomItem(true)
  }

  const onSaveSuccessHandler = async () => {
    try {
      setIsAddCustomItem(false)
      const customItemListResponse = await getCustomItemList({
        type: type,
        id: workOrderData.id
      })

      if (customItemListResponse.status === 200) {
        onSaveCustomItem(customItemListResponse.data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { type, id: workOrderData.id } })
    }
  }
  return (
    <AdasBox>
      {isAddCustomItem && (
        <AdasDrawer anchor={'right'} open={isAddCustomItem} onClose={() => setIsAddCustomItem(false)}>
          <AdasBox className='drawer-title-container'>
            <AdasTypography variant='h6'>Custom Work Items & Credits</AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                color: theme.palette.info.main
              }}
              onClick={() => setIsAddCustomItem(false)}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <CustomItemForm
            type={type}
            cancel={() => setIsAddCustomItem(false)}
            isNew={!existingCustomItem}
            workOrderData={workOrderData}
            customItemData={existingCustomItem}
            onSaveSuccess={onSaveSuccessHandler}
          />
        </AdasDrawer>
      )}
      <Accordion sx={{ cursor: 'pointer' }} defaultExpanded={defaultExpanded}>
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <AdasBox display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <AdasTypography variant='body1' sx={{ fontWeight: theme.typography.fontWeightMedium }}>
              Custom Charges & Discounts
            </AdasTypography>
            <AdasTypography variant='body1' sx={{ fontWeight: theme.typography.fontWeightMedium, marginRight: { md: '2rem' } }}>
              {currencyFormatter(customItemTotal)}
            </AdasTypography>
          </AdasBox>
        </AccordionSummary>
        <AdasAccordionDetails sx={{ paddingX: { md: '52px' } }}>
          <ItemList
            itemList={customItemList}
            onClickEdit={(props) => onClickEditHandler(props)}
            name='additional items'
            isCustomItem={true}
            disabled={disabled}
          />
          <AdasBox sx={{ textAlign: 'end' }}>
            <AdasButton startIcon={<AddIcon />} sx={{}} onClick={addCustomItemHandler} disabled={disabled}>
              Add New Item
            </AdasButton>
          </AdasBox>
        </AdasAccordionDetails>
      </Accordion>
    </AdasBox>
  )
}

CustomItem.propTypes = {
  type: PropTypes.string,
  existingCustomItemList: PropTypes.array,
  workOrderData: PropTypes.object,
  onSaveCustomItem: PropTypes.func,
  customItemTotal: PropTypes.number,
  disabled: PropTypes.bool
}
