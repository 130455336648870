import React from 'react'
import { domToReact } from 'html-react-parser'
import dayjs from 'dayjs'
import { DATE_FORMATS, ROLES_HIERARCHY } from '../constants'
import { downloadOEMStatementPDF } from 'api/api'
import { Logger } from '../logger'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

dayjs.extend(utc)
dayjs.extend(timezone)

export const phoneNumberFormat = (input) => {
  if (input) {
    input = input.replace(/\D/g, '')

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10)

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length
    if (size === 0) {
      /*eslint no-empty: "error"*/
    } else if (size < 4) {
      input = '(' + input
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6)
    } else {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10)
    }
    return input
  } else {
    return ''
  }
}

export function currencyFormatter(value) {
  const numberValue = Number(value)
  if (Number.isNaN(numberValue)) {
    return '$XX.XX'
  }

  const absValue = Math.abs(numberValue)
  const formattedValue = absValue.toLocaleString('en-us', {
    minimumFractionDigits: 2
  })

  return numberValue < 0 ? `-$${formattedValue}` : `$${formattedValue}`
}
export function ccidFormatter(value) {
  if (value === undefined || value === null) {
    return ''
  }
  const valueString = value.toString()
  return 'CC-' + valueString.padStart(4, '0')
}

export const zipCodeInput = (input) => {
  if (input) {
    input = input.replace(/\D/g, '')
    input = input.substring(0, 5)
    return input
  } else {
    return ''
  }
}

export const currencyInput = (input) => {
  if (input) {
    input = input.replace(/\D/g, '')
    return input
  } else {
    return ''
  }
}

export const sortByName = (a, b) => {
  const name1 = a.name.toUpperCase()
  const name2 = b.name.toUpperCase()

  let comparison = 0

  if (name1 > name2) {
    comparison = 1
  } else if (name1 < name2) {
    comparison = -1
  }
  return comparison
}

export const sortByDate = (a, b) => {
  return Date.parse(a.created) - Date.parse(b.created)
}

export const getRole = (user) => {
  if (user.is_admin) {
    return 'Admin'
  } else if (user.is_owner) {
    return 'Owner'
  } else if (user.is_manager) {
    return 'Manager'
  } else if (user.role) {
    return user.role
  } else {
    return 'Tech'
  }
}

export const validations = {
  name: (value) => {
    return !!value
  },
  email: (value) => {
    return value && value.match(/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/)
  },
  phone: (value) => {
    if (value) {
      return value.replace(/\D/g, '').length >= 10
    }
    return false
  },
  price: (value) => {
    return !!value || value === 0
  },
  city: (value) => {
    return value && value.match(/^[A-Za-z-' ]+$/)
  },
  state: (value) => {
    return !!value
  },
  zip: (value) => {
    return !!value
  },
  address1: (value) => {
    return !!value
  },
  category: (value) => {
    return !!value
  },
  vin: (value) => {
    if (value) {
      return value.length === 17
    }
    return false
  },
  qboClassName: (value) => {
    return value.match(/^[A-Za-z]*$/)
  },
  optionalEmail: (value) => {
    if (!value) return true
    return value && value.match(/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/)
  },
  requiredAlphanumericText: (value) => {
    return /^[A-Za-z0-9\s]{1,50}$/.test(value)
  },
  alphanumericText: (value) => {
    if (!value) return true
    return /^[A-Za-z0-9\s]{1,50}$/.test(value)
  },
  alphabeticText: (value) => {
    if (!value) return true
    return /^[a-zA-Z\s]*$/.test(value)
  },
  optionalZip: (value) => {
    if (!value) return true
    return value.length === 5
  },
  optionalPhone: (value) => {
    if (!value) return true
    if (value) {
      return value.replace(/\D/g, '').length >= 10
    }
    return false
  }
}

const validateScore = (value, limit = 10) => {
  if (!value || value === ' ') {
    return false
  }
  const intValue = parseInt(value)
  return 0 <= intValue && intValue <= limit
}

export const scoreValidations = {
  preCalDocScore: (value) => validateScore(value),
  preCalPhotoScore: (value) => validateScore(value),
  prodCalScore: (value) => validateScore(value),
  prodCalSrvCustScore: (value) => validateScore(value),
  calDocScore: (value) => validateScore(value, 20),
  calPhotoScore: (value) => validateScore(value, 20),
  postDocScore: (value) => validateScore(value),
  postPhotoScore: (value) => validateScore(value)
}

export const isTech = (user) => {
  return !user.is_admin && !user.is_owner && !user.is_manager
}

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const processCenterRoles = (user) => {
  if (user.is_admin) {
    return ['Admin', []]
  }
  if (user.is_owner) {
    return ['Owner', user.calibrationCentersOwned]
  }
  if (user.is_manager) {
    return ['Manager', user.calibrationCentersManaged]
  }
  if (user.calibrationCentersTech.length > 0) {
    return ['Tech', user.calibrationCentersTech]
  }
  return ['?', []]
}

export function downloadAsFile(data, fileName) {
  const element = document.createElement('a')
  element.href = URL.createObjectURL(data)
  element.download = fileName
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const formatDate = (date, format) => {
  if (date) {
    return dayjs(date).format(format)
  }
  return null
}

export const formatTime = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  let formattedTime = ''
  if (days > 0) formattedTime += `${days} ${days > 1 ? 'days' : 'day'}`
  else if (hours > 0) formattedTime += `${hours} ${hours > 1 ? 'hours' : 'hour'}`
  else if (minutes > 0) formattedTime += `${minutes} ${minutes > 1 ? 'mins' : 'min'}`
  else formattedTime += `${secs} seconds`

  return formattedTime
}

export const convertToLocalTimezone = (str) => {
  const adjustedDateTimeString = str
    .replace(' ', 'T') // Correctly separate date and time with T
    .replace(/\.\+/, '+')
  const date = new Date(adjustedDateTimeString)
  return date.toLocaleDateString()
}

export const roundToGreatestInteger = (number) => {
  // Check if the number has a decimal part
  if (number % 1 !== 0) {
    // If there's a decimal part, add 1 to its integer part
    return Math.floor(number) + 1
  } else {
    // If the number is already an integer, return the same number
    return number
  }
}

export const debounce = (func, delay) => {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export const formatDateTime = (date, format) => {
  if (date) {
    const dateString = dayjs(date).format(format)
    const hours = (date.getHours() % 12 || 12).toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${dateString}  ${hours}h${minutes}m`
  }
  return null
}

export const htmlParseOptions = {
  replace: (domNode) => {
    if (domNode.type === 'tag' && domNode.name === 'p') {
      // Modify attributes or add styles here
      const modifiedProps = {
        ...domNode.attribs, // Include existing attributes
        style: { margin: '0', marginBottom: '8px', ...domNode.attribs.style } // Override margin to 0
      }
      return React.createElement(domNode.name, modifiedProps, domToReact(domNode.children, htmlParseOptions))
    }
    // Return undefined to preserve the original element
    return undefined
  }
}

export const checkPermission = ({ currentUserRole, targetUserRole }) => {
  return ROLES_HIERARCHY.indexOf(currentUserRole) > ROLES_HIERARCHY.indexOf(targetUserRole)
}

export const handleDownloadOEMStatementPDF = async (id) => {
  try {
    const dataBlob = await downloadOEMStatementPDF(id)
    const downloadURL = URL.createObjectURL(dataBlob.data)
    window.open(downloadURL, '_blank')
  } catch (error) {
    Logger.error({ payload: { file: 'utils', function: 'handleDownloadOEMStatementPDF' }, message: error })
  }
}

export const convertToMBSize = (size) => `${(size / 1000000).toFixed(2)} MB`

export const groupSlotsBySameDate = (
  data,
  dateKey = 'overrides_date',
  startTimeKey = 'start_time',
  endTimeKey = 'end_time',
  slotsKey = 'slots'
) => {
  const groupedData = data.reduce((acc, current) => {
    const date = current[dateKey]
    const start = `${current[startTimeKey][0].toString().padStart(2, '0')}:${current[startTimeKey][1].toString().padStart(2, '0')}`
    const end = `${current[endTimeKey][0].toString().padStart(2, '0')}:${current[endTimeKey][1].toString().padStart(2, '0')}`

    if (!acc[date]) {
      acc[date] = { [dateKey]: date, [slotsKey]: [] }
    }

    if (start === '00:00' && end === '00:00') {
      acc[date][slotsKey] = []
    } else {
      acc[date][slotsKey].push({ start, end })
    }
    return acc
  }, {})

  return Object.values(groupedData)
}

export function convertUTCToOffset(utcTime, offset) {
  const [hours, minutes] = offset.split(':')
  const totalOffset = parseInt(hours) * 60 + parseInt(minutes)
  return dayjs(utcTime).utc().add(totalOffset, 'minute')
}

// export const mapEventsToCalendarFormat = (events) => {
//   const filteredEvents = events.filter((event) => event.status !== 'CANCELLED')
//   return filteredEvents.map((event) => {
//     const { appointment_date, start_time, duration, organisation, client, status, vehicle, notes, assignee } = event
//     const formattedStartTime = `${appointment_date}T${start_time[0].toString().padStart(2, '0')}:${start_time[1].toString().padStart(2, '0')}:00Z`
//     const startDateTime = convertUTCToOffset(formattedStartTime, organisation.timezone_at_value).format()
//     console.log(`🚀 ~ returnfilteredEvents.map ~ startDateTime:`, startDateTime)
//     const endDateTime = incrementByDuration(startDateTime, duration).format()
//     console.log(`🚀 ~ returnfilteredEvents.map ~ endDateTime:`, endDateTime)
//     return {
//       id: event.id,
//       title: event.client?.full_name || 'No title',
//       start: startDateTime, // Start time in UTC
//       end: endDateTime, // End time in UTC
//       extendedProps: {
//         organisation: event.organisation,
//         type: event.status,
//         client: event.client,
//         vehicle: event.vehicle,
//         status: event.status,
//         assignee: assignee,
//         notes: event.notes,
//         start: startDateTime, // Start time in UTC
//         end: endDateTime
//       }
//     }
//   })
// }

function incrementByDuration(time, duration) {
  return time.add(duration, 'minute')
}

export const getTimeZoneByOffset = (offset) => {
  switch (offset) {
    case '-10:00':
      return 'America/Adak'
    case '-09:00':
      return 'America/Anchorage'
    case '-08:00':
      return 'America/Los_Angeles'
    case '-07:00':
      return 'America/Denver'
    case '-06:00':
      return 'America/Chicago'
    case '-05:00':
      return 'America/New_York'
    case '-04:00':
      return 'America/Halifax'
    default:
      return 'UTC'
  }
}

export const getTimeZoneByCode = (region) => {
  switch (region) {
    case 'US_HAWAII':
      return 'Pacific/Honolulu' // Hawaii
    case 'US_ALEUTIAN':
      return 'America/Adak' // Aleutian Time
    case 'US_ALASKA':
      return 'America/Anchorage' // Alaska Time
    case 'US_PACIFIC':
      return 'America/Los_Angeles' // Pacific Time
    case 'US_ARIZONA':
      return 'America/Phoenix' // Arizona Time
    case 'US_MOUNTAIN':
      return 'America/Denver' // Mountain Time
    case 'US_CENTRAL':
      return 'America/Chicago' // Central Time
    case 'US_INDIANA_STARKE':
      return 'America/Indiana/Knox' // Indiana-Starke Time
    case 'US_EASTERN':
      return 'America/New_York' // Eastern Time
    case 'US_EAST_INDIANA':
      return 'America/New_York' // East-Indiana Time
    case 'US_MICHIGAN':
      return 'America/Detroit' // Michigan Time
    case 'US_ATLANTIC':
      return 'America/Halifax' // Atlantic Time
    default:
      return 'UTC' // Default case
  }
}

export const mapOverrideToCalendarFormat = (overrides = [], offset) => {
  return overrides.map((data) => {
    const { id, overrides_date, start_time, is_unavailable } = data
    const end_time = is_unavailable ? [23, 59] : data.end_time
    const formattedStartTime = `${overrides_date}T${start_time[0].toString().padStart(2, '0')}:${start_time[1].toString().padStart(2, '0')}:00Z`
    const formattedEndTime = `${overrides_date}T${end_time[0].toString().padStart(2, '0')}:${end_time[1].toString().padStart(2, '0')}:00Z`
    const startDateTime = dayjs(formattedStartTime).tz(offset, false).utc()
    const endDateTime = dayjs(formattedEndTime).tz(offset, false).utc()

    return {
      id,
      start: startDateTime.format(),
      end: endDateTime.format(),
      extendedProps: {
        override: true,
        offset: offset
      }
    }
  })
}

export const mapEventsToCalendarFormat = (events) => {
  const filteredEvents = events.filter((event) => event.status !== 'CANCELLED')
  return filteredEvents.map((event) => {
    const { appointment_date, start_time, duration, organisation, client, status, vehicle, notes, assignee } = event
    const formattedStartTime = `${appointment_date}T${start_time[0].toString().padStart(2, '0')}:${start_time[1].toString().padStart(2, '0')}:00Z`
    const offset = getTimeZoneByCode(organisation.timezone_at)

    const startDateTime = dayjs(formattedStartTime).tz(offset) // Keep as Day.js object
    // const startDateTime = dayjs.utc(formattedStartTime).tz(offset)
    const endDateTime = incrementByDuration(startDateTime, duration).format()
    return {
      id: event.id,
      override: false,
      title: event.client?.full_name || 'No title',
      start: startDateTime.format(), // Start time in UTC
      end: endDateTime, // End time in UTC
      extendedProps: {
        organisation: event.organisation,
        type: event.status,
        client: event.client,
        vehicle: event.vehicle,
        status: event.status,
        assignee: assignee,
        notes: event.notes,
        duration: event.duration,
        start: startDateTime.format(), // Start time in UTC
        end: endDateTime,
        offset: offset
      }
    }
  })
}

export const formatExistingTimeOrReturn = (date, time, timeZoneCode, duration) => {
  if (!date || !time) return ''

  // Format the start time
  const formattedStartTime = `${date}T${time[0].toString().padStart(2, '0')}:${time[1].toString().padStart(2, '0')}:00Z`

  // Ensure convertUTCToOffset returns a valid Day.js object or parse its result using Day.js

  const offset = getTimeZoneByCode(timeZoneCode)
  const startDateTime = dayjs(formattedStartTime).tz(offset) // Keep as Day.js object

  // Now use Day.js to format the date and time
  const newDate = startDateTime.format('YYYY-MM-DD') // Extract date
  const newTime = startDateTime.format('HH:mm') // Extract time
  const startTime = startDateTime.format(DATE_FORMATS.ISO_TIME_STRING)
  const endTime = dayjs(newTime, 'HH:mm').add(duration, 'minute').format(DATE_FORMATS.ISO_TIME_STRING)
  const newEndTime = dayjs(newTime, 'HH:mm').add(duration, 'minute').format('HH:mm')
  // Return the date and time as separate strings
  return { newDate, newTime, startTime, endTime, newEndTime }
}

export const formatPhoneNumber = (phone) => {
  const cleaned = ('' + phone).replace(/\D/g, '') // Remove any non-digit characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/) // Match groups of 3-3-4 digits

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return phone // Return the original input if it doesn't match the expected format
}
export const daysOfWeekMap = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
}
