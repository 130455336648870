import PropTypes from 'prop-types'
import { AdasBox, AdasTypography } from '@components/wrapper-components'
import { WORK_ORDER_TYPE } from 'constants'

export const IdDisplay = ({ itemId, roNumber, itemType, serviceOnly }) => {
  return (
    <AdasBox
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'flex-start', md: 'center' }
      }}
    >
      <AdasBox
        sx={{
          display: 'flex',
          alignItems: { xs: 'flex-start', md: 'center' }
        }}
      >
        <AdasBox
          sx={{
            display: 'flex',
            marginRight: { xs: '.2rem' }
          }}
        >
          <AdasTypography variant='body2' color={'secondary'}>
            <span>{itemType} #</span>
            {itemId}
          </AdasTypography>
        </AdasBox>
        <AdasBox
          sx={{
            display: 'flex'
          }}
        >
          <AdasTypography variant='body2' color={'secondary'} sx={{ marginRight: { xs: '.2rem' } }}>
            |
          </AdasTypography>
          <AdasTypography variant='body2' color={'secondary'}>
            RO #{roNumber}
          </AdasTypography>
        </AdasBox>
      </AdasBox>
      {serviceOnly !== undefined && (
        <AdasBox
          sx={{
            display: 'flex',
            marginLeft: { xs: '0', md: '.2rem' }
          }}
        >
          <AdasTypography
            variant='body2'
            color={'secondary'}
            sx={{ display: { xs: 'none', md: 'inline' }, marginRight: { xs: '0', md: '.2rem' } }}
          >
            |
          </AdasTypography>
          <AdasTypography variant='body2' color={'secondary'}>
            {WORK_ORDER_TYPE[serviceOnly] || ''}
          </AdasTypography>
        </AdasBox>
      )}
    </AdasBox>
  )
}

IdDisplay.propTypes = {
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  roNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  itemType: PropTypes.string.isRequired,
  sx: PropTypes.object
}
