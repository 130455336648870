import CloseIcon from '@mui/icons-material/Close'
import { AdasBox, AdasButton, AdasDrawer, AdasPaper, AdasTypography } from '@components/wrapper-components'
import theme from 'theme/theme'
import AdasCustomStepper from '@components/adas-custom-stepper/AdasCustomStepper'
import { FileUploadOutlined } from '@mui/icons-material'
import { useCallback } from 'react'
import { CLIENT_LIST_UPLOAD_LIMIT } from 'constants'
import { TEXT_CONSTANTS } from 'constants'
import { FILE_NAME_LIMIT_IN_CHARS } from 'constants'
export const ImportCSVComponent = ({
  downloadCSV,
  handleValidateCSV,
  CSVValidationResponse,
  activeStep,
  setActiveStep,
  file,
  setFile,
  open,
  onClose,
  handleCSVUpload,
  csvValid,
  IMPORT_CLIENT_STEPPER,
  GENERIC_MESSAGES,
  CSVUploadResponse,
  setCsvValid,
  fileUploadErrorMessage,
  setFileUploadErrorMessage,
  title
}) => {
  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0]
    if (!file) return

    if (file.size > CLIENT_LIST_UPLOAD_LIMIT) {
      setFileUploadErrorMessage(TEXT_CONSTANTS.FILE_LIMIT_EXCEEDED)
    } else {
      setFile(file)
      setCsvValid(true)
      setFileUploadErrorMessage('')
    }
    if (file.name.length > FILE_NAME_LIMIT_IN_CHARS) {
      return setFileUploadErrorMessage(TEXT_CONSTANTS.FILE_NAME_SIZE_EXCEEDED)
    }

    // Reset the input value so that the same file can be uploaded again
    e.target.value = null
  }, [])
  return (
    <AdasDrawer inputStyles={{ display: 'flex', justifyContent: 'center' }} anchor={'right'} open={open} onClose={onClose}>
      <AdasBox sx={{ padding: 2, width: { xs: '100%', sm: 400 } }}>
        <AdasBox display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <AdasTypography variant='h6'>{title}</AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              padding: '2px',
              cursor: 'pointer',
              color: theme.palette.info.main
            }}
            onClick={onClose}
          >
            <CloseIcon fontSize='small' />
          </AdasButton>
        </AdasBox>

        <AdasPaper sx={{ p: 2 }}>
          <AdasCustomStepper
            errorSteps={!csvValid ? [IMPORT_CLIENT_STEPPER[1]] : []}
            steps={IMPORT_CLIENT_STEPPER}
            activeStep={activeStep}
          />
          {activeStep === IMPORT_CLIENT_STEPPER[0] ? (
            <>
              <AdasTypography sx={{ mt: '32px' }} color='textSecondary' variant='subtitle2' mb={1}>
                DOWNLOAD TEMPLATE
              </AdasTypography>
              <AdasButton variant='contained' color='primary' sx={{ mb: 2 }} onClick={() => downloadCSV({ template: true })}>
                DOWNLOAD
              </AdasButton>
              <AdasTypography
                sx={{
                  textDecoration: 'underline'
                }}
                color='textSecondary'
                variant='body2'
                mb={1}
              >
                CSV Instructions
              </AdasTypography>
              <AdasBox component='ul' sx={{ pl: 3, mb: 2, listStyleType: 'disc' }}>
                {GENERIC_MESSAGES.CLIENT_IMPORT_INSTRUCTIONS.map((instruction, index) => (
                  <AdasTypography key={index} component='li' variant='body2' color='textSecondary' sx={{ mb: 1 }}>
                    {instruction}
                  </AdasTypography>
                ))}
              </AdasBox>
              <AdasBox sx={{ display: 'flex', alignItems: 'baseline', gap: 1, mb: '11px' }}>
                <AdasButton variant='outlined' color='primary' component='label' startIcon={<FileUploadOutlined />}>
                  <input
                    type='file'
                    hidden
                    onChange={handleFileChange}
                    aria-hidden='true'
                    aria-label='File Upload'
                    accept='.csv' // Adjust accept property for allowed file types
                  />
                  Choose File
                </AdasButton>
                {file?.name && (
                  <AdasTypography variant='caption' color='textSecondary'>
                    {file.name}
                  </AdasTypography>
                )}
              </AdasBox>
              <AdasTypography variant='caption' color={fileUploadErrorMessage ? 'error' : 'textSecondary'}>
                {fileUploadErrorMessage ? fileUploadErrorMessage : 'Please upload the CSV with correct format'}
              </AdasTypography>
              <AdasBox display='flex' justifyContent='flex-end' mt={3}>
                <AdasButton variant='outlined' color='primary' onClick={onClose}>
                  CANCEL
                </AdasButton>
                <AdasButton
                  disabled={fileUploadErrorMessage || !file}
                  variant='contained'
                  color='primary'
                  sx={{ ml: '.5rem' }}
                  onClick={handleValidateCSV}
                >
                  NEXT
                </AdasButton>
              </AdasBox>
            </>
          ) : activeStep === IMPORT_CLIENT_STEPPER[1] ? (
            <>
              <AdasBox component='ul' sx={{ mt: '32px', pl: 3, mb: 2, listStyleType: 'disc' }}>
                {CSVValidationResponse?.map((message, index) => (
                  <AdasTypography key={index} component='li' variant='body2' color={!csvValid ? 'error' : 'textSecondary'} sx={{ mb: 1 }}>
                    {message}
                  </AdasTypography>
                ))}
              </AdasBox>
              <AdasBox display='flex' justifyContent='flex-end' mt={3}>
                {csvValid ? (
                  <AdasButton variant='outlined' color='primary' onClick={onClose}>
                    CANCEL
                  </AdasButton>
                ) : (
                  <AdasButton
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      setActiveStep((step) => step - 1)
                      setCsvValid(true)
                    }}
                  >
                    PREVIOUS
                  </AdasButton>
                )}
                <AdasButton disabled={!csvValid} variant='contained' color='primary' sx={{ ml: '.5rem' }} onClick={handleCSVUpload}>
                  CONFIRM
                </AdasButton>
              </AdasBox>
            </>
          ) : (
            <>
              <AdasBox sx={{ mt: '32px', p: '6px 16px', mb: 2, backgroundColor: '#50535b', borderRadius: '4px' }}>
                <AdasBox component='ul' sx={{ listStyleType: 'disc' }}>
                  {CSVUploadResponse.map((message, index) => (
                    <AdasTypography
                      key={index}
                      component='li'
                      variant='body2'
                      color={!csvValid ? 'error' : 'textSecondary'}
                      sx={{ mb: 1, ml: '8px', color: '#fff' }}
                    >
                      {message}
                    </AdasTypography>
                  ))}
                </AdasBox>
              </AdasBox>
              <AdasBox display='flex' justifyContent='flex-end' mt={3}>
                <AdasButton variant='contained' color='primary' sx={{ ml: '.5rem' }} onClick={onClose}>
                  DISMISS
                </AdasButton>
              </AdasBox>
            </>
          )}
        </AdasPaper>
      </AdasBox>
    </AdasDrawer>
  )
}
