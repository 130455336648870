export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
export const AWS_REGION = process.env.REACT_APP_AWS_REGION
export const LOG_GROUP = process.env.REACT_APP_LOG_GROUP
export const LOG_STREAM = process.env.REACT_APP_LOG_STREAM
export const BACKEND_URL = process.env.REACT_APP_BACKEND_ENDPOINT
export const DATADOG_APP_ID = process.env.REACT_APP_DATA_DOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN
export const APP_ENV = process.env.NODE_ENV
export const IPINFO_TOKEN = process.env.REACT_APP_IPINFO_TOKEN
