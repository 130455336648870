import { useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useHistory } from 'react-router-dom'

import { useCenterStore } from '@caradasstore/CenterStore'
import { SearchOrAddData } from '@components/common'
import { AdasBox, AdasButton, AdasContainer, AdasDrawer, AdasPaper, AdasTable, AdasTypography } from '@components/wrapper-components'
import { CLIENT_LIST_HEADERS, TABLE_HEADER_STYLE, CLIENT_TYPE_ENUM, getClientDetailsUrl } from 'constants'
import { getClients, uploadClientCSV, validateClientCSV } from '../../api/api'
import theme from 'theme/theme'
import { GENERIC_MESSAGES } from 'constants'
import { downloadAsFile } from 'utils/utils'
import { Logger } from '../../logger'
import { ClientForm } from '@components/client'
import { useUserStore } from '@caradasstore/UserStore'
import { ImportCSVComponent } from '@components/import-form/ImportCSVComponent'
const IMPORT_CLIENT_STEPPER = [0, 1]

export const ClientListPage = () => {
  const [searchInput, setSearchInput] = useState('')
  const [fullClientList, setFullClientList] = useState([])
  const [existingClientNames, setExistingClientNames] = useState([])
  const [clients, setClients] = useState([])
  const [isAddClient, setIsAddClient] = useState(false)
  const [sort, setSort] = useState('ASC')
  const [openImportForm, setOpenImportForm] = useState(false)
  const [activeStep, setActiveStep] = useState(IMPORT_CLIENT_STEPPER[0])
  const [file, setFile] = useState(null)
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState(null)
  const [csvValid, setCsvValid] = useState(true)
  const [CSVValidationResponse, setCSVValidationResponse] = useState(null)
  const [CSVUploadResponse, setCSVUploadResponse] = useState(null)
  const ccid = useCenterStore((store) => store.centerDetail?.id)
  const history = useHistory()
  const user = useUserStore((store) => store.currentUser)

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
  }

  const clickSortHandler = useCallback(
    (id) => {
      if (id === 'name') setSort(sort === 'ASC' ? 'DESC' : 'ASC')
    },
    [sort]
  )

  const fetchClient = useCallback(async () => {
    const clientListResponse = await getClients({
      ccid: ccid,
      isActiveOnly: false
    })
    if (clientListResponse.status === 200) {
      setFullClientList(clientListResponse.data)
      setExistingClientNames(clientListResponse.data.map((x) => x.name))
    }
  }, [ccid])

  // Initial Load
  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  const sortByName = useCallback((a, b, sort) => {
    const name1 = sort === 'ASC' ? a.name.toUpperCase() : b.name.toUpperCase()
    const name2 = sort === 'ASC' ? b.name.toUpperCase() : a.name.toUpperCase()

    let comparison = 0

    if (name1 > name2) {
      comparison = 1
    } else if (name1 < name2) {
      comparison = -1
    }
    return comparison
  }, [])

  useEffect(() => {
    let list = [...fullClientList]
    if (searchInput) {
      list = list.filter((client) => client.name.toLowerCase().includes(searchInput))
    }
    list = list.map((client) => {
      const typeDescription = CLIENT_TYPE_ENUM[client.type] || ''
      return { ...client, typeDescription }
    })
    list.sort((a, b) => sortByName(a, b, sort))

    setClients(list)
  }, [sort, fullClientList, sortByName, searchInput])

  const clientInfoHandler = (props) => {
    history.push(getClientDetailsUrl(props?.id))
  }

  const downloadClients = useCallback(
    ({ template = false }) => {
      let clientExport = template
        ? []
        : fullClientList.map((client, index) => [
            '"' + client.name + '"',
            '"' + client.type + '"',
            client.vehicle_count ? '"' + client.vehicle_count + '"' : '"' + 0 + '"',
            '"' + client.is_active + '"',
            '"' + client.phone + '"',
            '"' + client.email + '"',
            '"' + [client.address1, client.address2].filter(Boolean).join(', ') + '"',
            '"' + client.city + '"',
            '"' + client.state + '"',
            '"' + client.zip + '"' + '\n'
          ])

      clientExport = [
        ['Name', 'Type', 'Potential Monthly RO Count', 'Status', 'Phone', 'Email', 'Address', 'City', 'State', 'Zip\n'],
        ...clientExport
      ]
      const fileName = 'clientExport.csv'
      const value = new Blob(clientExport, { type: 'text/plain' })
      downloadAsFile(value, fileName)
    },
    [fullClientList]
  )

  const handleValidateCSV = useCallback(async () => {
    if (file instanceof File) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      try {
        const response = await validateClientCSV({ file: formData, ccid })
        if (response.data.status === 'error') {
          setCsvValid(false)
        } else {
          setCsvValid(true)
        }
        setCSVValidationResponse(response.data.messages)
        setActiveStep((step) => step + 1)
      } catch (error) {
        if (error.status === 400) {
          setCSVValidationResponse(error.response.data.messages)
          setCsvValid(false)
          setActiveStep((step) => step + 1)
        } else {
          Logger.error({ message: error, payload: { file: 'ClientListPage', method: 'handleValidateCSV' } })
        }
      }
    }
  }, [file, validateClientCSV, setActiveStep, setCSVValidationResponse, setCsvValid, ccid])

  const handleCSVUpload = useCallback(async () => {
    if (file instanceof File) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      const response = await uploadClientCSV({ file: formData, ccid })
      setCSVUploadResponse(response.data.messages)
      setActiveStep((step) => step + 1)
    }
  }, [file, uploadClientCSV, setActiveStep, ccid])

  const handleCloseImportClient = useCallback(() => {
    setFile(null)
    setFileUploadErrorMessage(null)
    setActiveStep(IMPORT_CLIENT_STEPPER[0])
    setCSVValidationResponse(null)
    setOpenImportForm(false)
    setCsvValid(true)
    fetchClient()
  }, [])

  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      <AdasPaper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          padding: '.75rem',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          mt: '30px'
        }}
      >
        <AdasBox sx={{ flex: { xs: 1, sm: 0.9, md: 0.9 }, mb: { xs: 2, sm: 0 }, display: 'flex', alignItems: 'center' }}>
          <SearchOrAddData
            searchInput={searchInput}
            maxHeight={'45px'}
            searchInputHandler={searchInputHandler}
            onAddClick={() => setIsAddClient(true)}
          />
        </AdasBox>
        <AdasBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: { md: 0 }
          }}
        >
          <AdasButton
            variant='contained'
            color='primary'
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '.7rem',
              height: '42px',
              minWidth: '120px'
            }}
            onClick={() => setOpenImportForm(true)}
          >
            Import CSV
          </AdasButton>
          <AdasButton
            variant='outlined'
            color='primary'
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '42px',
              minWidth: '120px'
            }}
            onClick={downloadClients}
          >
            Export CSV
          </AdasButton>
        </AdasBox>
      </AdasPaper>
      {isAddClient && (
        <AdasDrawer anchor={'right'} open={isAddClient} onClose={() => setIsAddClient(false)}>
          <AdasBox className='drawer-title-container'>
            <AdasTypography variant='h6'>Client Info</AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                color: theme.palette.info.main
              }}
              onClick={() => setIsAddClient(false)}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <ClientForm
            onSaveSuccess={fetchClient}
            clibrationCenterId={ccid}
            close={() => setIsAddClient(false)}
            existingClients={existingClientNames}
          />
        </AdasDrawer>
      )}
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        {clients.length > 0 && (
          <AdasTable
            containerProps={{ component: AdasPaper }}
            tableProps={{
              sx: {
                marginTop: '20px',
                marginBottom: '20px',
                '.MuiTableCell-root': { border: 'none', padding: '2px 20px' },
                overflowX: 'scroll'
              },
              'aria-label': 'clients table'
            }}
            columns={CLIENT_LIST_HEADERS}
            sortField='name'
            sortDirection={sort}
            onSort={clickSortHandler}
            headerStyle={TABLE_HEADER_STYLE}
            data={clients}
            onSelect={clientInfoHandler}
          />
        )}
      </AdasPaper>
      <ImportCSVComponent
        open={openImportForm}
        title='Bulk Import Clients'
        onClose={handleCloseImportClient}
        handleValidateCSV={handleValidateCSV}
        handleCSVUpload={handleCSVUpload}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        csvValid={csvValid}
        downloadCSV={downloadClients}
        CSVValidationResponse={CSVValidationResponse}
        file={file}
        IMPORT_CLIENT_STEPPER={IMPORT_CLIENT_STEPPER}
        GENERIC_MESSAGES={GENERIC_MESSAGES}
        CSVUploadResponse={CSVUploadResponse}
        setCsvValid={setCsvValid}
        setFile={setFile}
        fileUploadErrorMessage={fileUploadErrorMessage}
        setFileUploadErrorMessage={setFileUploadErrorMessage}
      />
    </AdasContainer>
  )
}
