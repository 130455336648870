import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import { productCategories } from '@components/product'
import { SortIndicator } from '@components/sort-indicator'
import { StatusInfo } from '@components/status-info'
import { AdasLink, AdasTypography } from '@components/wrapper-components'
import { SupportStatusInfo } from '@components/status-info'
import { ccidFormatter, currencyFormatter, formatDateTime, getRole } from 'utils/utils'
import { DATE_FORMATS } from '../../constants'

const renderers = {
  name: ({ content, onSelect }) => (
    <AdasLink
      href='#'
      underline='none'
      onClick={(e) => {
        e.preventDefault() //Added this to removing # after each route
        onSelect(content, 'name')
      }}
    >
      {content.name}
    </AdasLink>
  ),
  is_active: ({ content }) => (
    <StatusInfo label={content.is_active ? 'Active' : 'Inactive'} color={content.is_active ? 'primary' : 'secondary'} />
  ),
  price: ({ content }) => currencyFormatter(content.price),
  category: ({ content }) => productCategories[content.category] || '-',
  id: ({ content }) => ccidFormatter(content.id),
  ccid: ({ content }) => content.ccid,
  center: ({ content, onSelect }) =>
    content.center ? (
      <AdasLink href='#' underline='none' onClick={() => onSelect(content, 'center')}>
        {content.center}
      </AdasLink>
    ) : (
      '-'
    ),
  opening_date: ({ content }) => (content.opening_date ? new Date(content.opening_date).toLocaleDateString() : '-'),
  created_at: ({ content }) => (content.created_at ? formatDateTime(new Date(content.created_at), DATE_FORMATS.ISO_DATE_STRING) : '-'),
  updated_at: ({ content }) => (content.updated_at ? formatDateTime(new Date(content.updated_at), DATE_FORMATS.ISO_DATE_STRING) : '-'),
  closed_at: ({ content }) => (content.closed_at ? formatDateTime(new Date(content.closed_at), DATE_FORMATS.ISO_DATE_STRING) : '-'),
  pbi_account_id: ({ content }) => content.pbi_account_id || '-',
  support_lead: ({ content }) => content.support_lead || '-',
  owners: ({ content }) => (content.owners.length > 0 ? content.owners[0].name : '-'),
  group_number: ({ content }) => content.group_number || '-',
  role: ({ content }) => getRole(content),
  default: ({ content, type }) => content[type],
  supportTicketId: ({ content, onSelect }) => (
    <AdasLink underline='none' sx={{ cursor: 'pointer' }} onClick={() => onSelect(content)}>
      {content.ticket_number}
    </AdasLink>
  ),
  supportTicketStatus: ({ content }) => <SupportStatusInfo value={content.status} type='VIEW' sx={{ cursor: 'pointer' }} />,
  is_scheduler_enabled: ({ content }) => (content.is_scheduler_enabled ? 'Active' : 'Inactive'),
  is_pgw_enabled: ({ content }) => (content.is_pgw_enabled ? 'Active' : 'Inactive'),
  jobTitle: ({ content }) => (content.jobTitle ? content.jobTitle : <AdasTypography sx={{ paddingLeft: '3%' }}>-</AdasTypography>)
}

export const AdasTable = ({
  containerProps,
  tableProps,
  columns,
  sortField,
  sortDirection,
  onSort,
  headerStyle,
  data,
  onSelect,
  tableRowProps
}) => (
  <TableContainer {...containerProps} sx={{ backgroundColor: '#ffff' }}>
    <Table {...tableProps}>
      {/* HEADER */}
      <TableHead>
        <TableRow
          sx={{
            '.MuiTableCell-root': { paddingBottom: '10px', ...headerStyle }
          }}
        >
          {columns.map((column) => (
            <TableCell
              key={column.id}
              sx={{ minWidth: column.minWidth, ...headerStyle }}
              align={column.align || 'left'}
              onClick={() => onSort(column.id)}
            >
              {column.label}
              {sortField === column.id && <SortIndicator direction={sortDirection} />}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      {/* BODY */}
      <TableBody>
        {data.map((content, index) => {
          return (
            <TableRow key={index} {...tableRowProps}>
              {columns.map(({ id }) => (
                <TableCell
                  key={id}
                  align={id === 'is_active' ? 'center' : 'left'}
                  sx={{
                    maxWidth: '0',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  <span style={content[`${id}Style`]}>{(renderers[id] || renderers.default)({ content, onSelect, type: id })}</span>
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>
)

AdasTable.propTypes = {
  containerProps: PropTypes.shape({
    component: PropTypes.elementType
  }),
  tableProps: PropTypes.object,
  columns: PropTypes.array.isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  headerStyle: PropTypes.object,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  columnLabels: PropTypes.array,
  tableRowProps: PropTypes.object
}
