import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import theme from '../../theme/theme'
import { forwardRef } from 'react'

const defaultStyles = {
  variant: 'body1',
  color: theme.typography.defaultColor
}

export const AdasTypography = forwardRef(({ variant = defaultStyles.variant, color, children, sx, ...props }, ref) => {
  return (
    <Typography ref={ref} variant={variant} color={color} sx={sx} {...props}>
      {children}
    </Typography>
  )
})

AdasTypography.displayName = 'AdasTypography'

AdasTypography.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object
}
