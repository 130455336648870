export const productCategories = {
  BLIND_SPOT_MONITORING: 'Blind Spot Monitoring',
  DYNAMIC_CALIBRATION: 'Dynamic Calibration',
  FRONT_CAMERA: 'Front Camera',
  FRONT_RADAR: 'Front Radar',
  VERIFY_ALL_ADAS_SYSTEMS: 'Initialize & Verify All ADAS Systems',
  LIDAR: 'Lidar',
  PARK_ASSIST: 'Park Assist',
  POST_CALIBRATION: 'Post Calibration',
  PRE_CALIBRATION: 'Pre Calibration',
  REAR_CAMERA: 'Rear Camera',
  SIDE_RADAR: 'Side Radar',
  STATIC_CALIBRATION: 'Static Calibration',
  SURROUND_VIEW_CAMERA: 'Surround View Camera',
  PARTS: 'Parts',
  LABOUR: 'Labour',
  SERVICES: 'Services'
}

export const categoryKeys = [
  'BLIND_SPOT_MONITORING',
  'DYNAMIC_CALIBRATION',
  'FRONT_CAMERA',
  'FRONT_RADAR',
  'VERIFY_ALL_ADAS_SYSTEMS',
  'LIDAR',
  'PARK_ASSIST',
  'POST_CALIBRATION',
  'PRE_CALIBRATION',
  'REAR_CAMERA',
  'SIDE_RADAR',
  'STATIC_CALIBRATION',
  'SURROUND_VIEW_CAMERA'
]
