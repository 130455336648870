import { useRef, useState, useEffect } from 'react'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import theme from 'theme/theme'
import { AdasBox, AdasCard, AdasTypography, AdasTooltip } from '@components/wrapper-components'
import { currencyFormatter } from '../../../utils/utils'
import { useDeviceQueries } from '@hooks/useDeviceQueries'

const ItemRow = ({ item, isCustomItem, disabled, onClickEdit, isMobile, isQuantity, type }) => {
  const nameRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (nameRef.current) {
      setIsTruncated(nameRef.current.scrollWidth > nameRef.current.offsetWidth)
    }
  }, [isMobile])

  const truncationStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }

  return (
    <AdasCard
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 15px',
        marginBottom: '8px',
        border: '1px solid #E0E0E0',
        boxShadow: 'none',
        flexWrap: isMobile ? 'wrap' : 'nowrap' // Wrap content on mobile
      }}
    >
      {/* Name Column */}
      <AdasBox
        sx={{
          flex: isMobile ? '1 1 50%' : '1 1 33%',
          minWidth: '0' // Required to make text truncation work
        }}
      >
        <AdasTooltip title={item.name} disableHoverListener={!isTruncated}>
          <AdasTypography ref={nameRef} sx={{ ...truncationStyles, width: '100%' }} variant='body2'>
            {item.name}
          </AdasTypography>
        </AdasTooltip>
      </AdasBox>

      {/* Quantity Column */}
      {isQuantity && (
        <AdasBox
          sx={{
            flex: isMobile ? '1 1 25%' : '1 1 33%',
            minWidth: '0',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AdasTypography sx={truncationStyles} variant='body2'>
            1
          </AdasTypography>
        </AdasBox>
      )}

      {/* Price Column */}
      <AdasBox
        sx={{
          flex: isMobile ? '1 1 25%' : '1 1 33%',
          minWidth: '0',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center'
        }}
      >
        <AdasTypography
          sx={{ ...truncationStyles }}
          variant='body2'
          color={item.item_type === 'discount' ? theme.typography.error : theme.typography.defaultColor}
        >
          {currencyFormatter(item.price)}
        </AdasTypography>
        {isCustomItem && !disabled && (
          <ModeEditOutlineOutlinedIcon
            color='primary'
            onClick={() => onClickEdit(item)}
            fontSize='small'
            sx={{ cursor: 'pointer', marginLeft: { xs: '4px', md: '8px' } }}
          />
        )}
      </AdasBox>
    </AdasCard>
  )
}

export const ItemList = ({ itemList, name, isCustomItem = false, disabled, onClickEdit, isQuantity = false, type }) => {
  const { isMobile } = useDeviceQueries()

  const truncationStyles = {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }

  return (
    <AdasBox>
      {itemList?.length > 0 ? (
        <AdasBox>
          <AdasBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
              flexWrap: isMobile ? 'wrap' : 'nowrap' // Wrap content on mobile
            }}
          >
            <AdasBox
              sx={{
                flex: isMobile ? '1 1 50%' : '1 1 33%',
                minWidth: '0' // Required to make text truncation work
              }}
            >
              <AdasTypography sx={{ ...truncationStyles, width: '100%' }} variant='body2'>
                Name
              </AdasTypography>
            </AdasBox>
            {isQuantity && (
              <AdasBox
                sx={{
                  flex: isMobile ? '1 1 25%' : '1 1 33%',
                  minWidth: '0',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <AdasTypography sx={truncationStyles} variant='body2'>
                  Qty
                </AdasTypography>
              </AdasBox>
            )}

            <AdasBox
              sx={{
                flex: isMobile ? '1 1 25%' : '1 1 33%',
                minWidth: '0',
                display: 'flex',
                justifyContent: 'end'
              }}
            >
              <AdasTypography sx={truncationStyles} variant='body2' mr={{ xs: '24px' }}>
                Price
              </AdasTypography>
            </AdasBox>
          </AdasBox>
          {itemList.map((item, index) => (
            <ItemRow
              key={index}
              item={item}
              isCustomItem={isCustomItem}
              disabled={disabled}
              onClickEdit={onClickEdit}
              isMobile={isMobile}
              type={type}
            />
          ))}
        </AdasBox>
      ) : (
        <AdasBox
          sx={{
            height: '106px',
            border: `1px dashed ${theme.palette.divider}`,
            display: 'flex',
            borderRadius: '4px'
          }}
        >
          <AdasTypography sx={{ margin: 'auto' }} variant='body2'>
            NO {name.toUpperCase()} ADDED
          </AdasTypography>
        </AdasBox>
      )}
    </AdasBox>
  )
}
