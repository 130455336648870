import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { sendInvoiceScorecardEmail } from '../../api/api.js'
import { useCenterStore, useLoadingStore, useUserStore } from '../../store/index.js'

import { AdasBox, AdasButton, AdasCard, AdasTypography } from '@components/wrapper-components'
import { AddEmailAddressButton, AddEmailInput, EmailItem } from '@components/email/EmailForm.js'
import { Logger } from '../../logger/index.js'

export const ScoreEmail = (props) => {
  const { onClose, contacts, invoiceId } = props
  const blockStyle = {
    padding: '16px 16px',
    backgroundColor: '#FDFBFF'
  }

  const [emailList, setEmailList] = useState({
    sendTos: [],
    bccs: []
  })

  const [isAddSendTo, setIsAddSendTo] = useState(false)
  const [isAddBcc, setIsAddBcc] = useState(false)
  const [addEmailList, setAddEmailList] = useState({
    sendTos: [],
    bccs: []
  })

  const setLoading = useLoadingStore((store) => store.setLoading)
  const currentUser = useUserStore((store) => store.currentUser)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const managementBccList = useMemo(() => {
    const { owners = [], managers = [] } = centerDetail
    const bccs = contacts?.bccs || []
    const shouldIncludeCurrentUser = !(currentUser.is_admin || currentUser.is_owner || currentUser.is_manager)

    const allUsersList = [...owners, ...managers, ...bccs, ...(shouldIncludeCurrentUser ? [currentUser] : [])]

    // Use a Set to efficiently filter duplicates by email
    const uniqueEmails = new Set()
    const uniqueList = allUsersList.filter((user) => {
      if (user?.email && user.is_active === true && !uniqueEmails.has(user.email)) {
        uniqueEmails.add(user.email)
        return true
      }
      return false
    })

    return uniqueList
  }, [centerDetail, contacts, currentUser])

  useEffect(() => {
    setEmailList({ sendTos: contacts?.sendTos?.filter((item) => item.def) || [], bccs: [] })
  }, [contacts])

  const saveEmail = useCallback(
    (props, isBcc) => {
      const listKey = isBcc ? 'bccs' : 'sendTos'
      const setFlag = isBcc ? setIsAddBcc : setIsAddSendTo

      setAddEmailList((prev) => ({
        ...prev,
        [listKey]: [...prev[listKey], props]
      }))

      setFlag(false)
    },
    [setAddEmailList, setIsAddBcc, setIsAddSendTo]
  )

  const handleCheckbox = useCallback((e, props, isBcc, isAddedEmail, updateFunc) => {
    if (isBcc) {
      if (isAddedEmail) {
        updateFunc((prev) => {
          const bccList = [...prev.bccs]
          const index = bccList.indexOf(props)
          bccList[index].checked = e.target.checked
          const updated = {
            ...prev,
            bccs: bccList
          }
          return updated
        })
      } else {
        updateFunc((prev) => {
          const bccList = [...prev.bccs]
          if (e.target.checked) {
            bccList.push(props)
          } else {
            const index = bccList.indexOf(props)
            bccList.splice(index, 1)
          }
          const updated = {
            ...prev,
            bccs: bccList
          }
          return updated
        })
      }
    } else {
      if (isAddedEmail) {
        updateFunc((prev) => {
          const sendToList = [...prev.sendTos]
          const index = sendToList.indexOf(props)
          sendToList[index].checked = e.target.checked
          const updated = {
            ...prev,
            sendTos: sendToList
          }
          return updated
        })
      } else {
        updateFunc((prev) => {
          const sendToList = [...prev.sendTos]
          if (e.target.checked) {
            sendToList.push(props)
          } else {
            const index = sendToList.indexOf(props)
            sendToList.splice(index, 1)
          }
          const updated = {
            ...prev,
            sendTos: sendToList
          }
          return updated
        })
      }
    }
  }, [])

  const reset = useCallback(() => {
    setEmailList({ sendTos: [], bccs: [] })
    setAddEmailList({ sendTos: [], bccs: [] })
    setIsAddBcc(false)
    setIsAddSendTo(false)
    onClose()
  }, [onClose])

  const sendEmailHandler = useCallback(async () => {
    const data = {
      sendTos: [...emailList.sendTos, ...addEmailList.sendTos.filter((item) => item.checked)],
      bccs: [...emailList.bccs, ...addEmailList.bccs.filter((item) => item.checked)],
      subject: contacts.subject,
      message: contacts.message,
      info: contacts.info
    }

    const params = {
      id: invoiceId,
      data
    }

    setLoading(true)
    try {
      const sendEmailResponse = await sendInvoiceScorecardEmail(params)
      if (sendEmailResponse?.status === 200) {
        reset()
      }
    } catch (error) {
      Logger.error({ message: error, payload: { path: 'sendEmailHandler' } })
    } finally {
      setLoading(false)
    }
  }, [emailList, addEmailList, contacts, reset])

  return (
    <>
      <AdasTypography sx={{ mt: '3rem' }} variant='h6'>
        Email Scorecard
      </AdasTypography>
      <AdasBox>
        <h5>SEND TO</h5>
        <AdasCard sx={blockStyle}>
          {contacts &&
            contacts.sendTos &&
            contacts.sendTos.length > 0 &&
            contacts.sendTos.map((item) => {
              return (
                <EmailItem
                  isChecked={emailList.sendTos.find((contact) => contact.email === item.email) ? true : false}
                  item={item}
                  updateFunc={setEmailList}
                  isBcc={false}
                  handleCheckbox={handleCheckbox}
                  key={item.email}
                />
              )
            })}
          {addEmailList.sendTos.length > 0 &&
            addEmailList.sendTos.map((item) => {
              return (
                <EmailItem
                  isChecked={item.checked}
                  item={item}
                  updateFunc={setAddEmailList}
                  isBcc={false}
                  isAddedEmail={true}
                  handleCheckbox={handleCheckbox}
                  key={item.email}
                />
              )
            })}
          {isAddSendTo && <AddEmailInput saveEmail={saveEmail} isBcc={false} setIsAddBcc={setIsAddBcc} setIsAddSendTo={setIsAddSendTo} />}
          <AddEmailAddressButton onClickFunc={() => setIsAddSendTo(true)} />
        </AdasCard>
      </AdasBox>
      <AdasBox>
        <h5>BCC (BLIND CARBON COPY)</h5>
        <AdasCard sx={blockStyle}>
          {managementBccList &&
            managementBccList.length > 0 &&
            managementBccList.map((item, index) => {
              return (
                <EmailItem
                  isChecked={emailList.bccs.find((contact) => contact.email === item.email) ? true : false}
                  item={item}
                  updateFunc={setEmailList}
                  isBcc={true}
                  handleCheckbox={handleCheckbox}
                  key={item.email + index}
                />
              )
            })}

          {addEmailList.bccs.length > 0 &&
            addEmailList.bccs.map((item) => {
              return (
                <EmailItem
                  isChecked={item.checked}
                  item={item}
                  updateFunc={setAddEmailList}
                  isBcc={true}
                  isAddedEmail={true}
                  handleCheckbox={handleCheckbox}
                  key={item.email}
                />
              )
            })}
          {isAddBcc && <AddEmailInput saveEmail={saveEmail} isBcc={true} setIsAddBcc={setIsAddBcc} setIsAddSendTo={setIsAddSendTo} />}
          <AddEmailAddressButton onClickFunc={() => setIsAddBcc(true)} />
        </AdasCard>
      </AdasBox>
      <AdasBox sx={{ my: '20px', float: 'right' }}>
        <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={reset} sx={{ marginRight: '10px' }}>
          Cancel
        </AdasButton>
        <AdasButton variant='contained' aria-label='send' color='primary' onClick={sendEmailHandler}>
          Email
        </AdasButton>
      </AdasBox>
    </>
  )
}

ScoreEmail.propTypes = {
  invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  contacts: PropTypes.object
}
