import { Drawer } from '@mui/material'
import PropTypes from 'prop-types'
import { AdasBox } from './AdasBox'

export const AdasDrawer = ({ anchor = 'right', open, onClose, children, inputStyles = {}, ...props }) => {
  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} {...props}>
      <AdasBox sx={{ width: { xs: '340px', sm: '420px' }, ...inputStyles }}>{children}</AdasBox>
    </Drawer>
  )
}

AdasDrawer.propTypes = {
  anchor: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
}
