import {
  AdasGrid,
  AdasButton,
  AdasFormControl,
  AdasTypography,
  AdasInputLabel,
  AdasSelect,
  AdasButtonGroup,
  AdasBox,
  AdasCard
} from '@components/wrapper-components'
import { useEffect, useState } from 'react'
import { useUserStore } from '@caradasstore/UserStore'
import { useCenterStore } from '@caradasstore/CenterStore'
import {
  getCalibrationCenter,
  getTimeZones,
  getOrganisationByReferenceId,
  updateEnableSchedulerForCalibrationCenter,
  updateEnablePGWForCalibrationCenter,
  updateTimeZoneOffsetForOrganisation
} from 'api/api'
import { Logger } from '../../../logger'
import { useLoadingStore, useOrganisationStore } from '@caradasstore'
export const SchedulerSettings = () => {
  const currentUser = useUserStore((store) => store.currentUser)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const setOrganisationDetail = useOrganisationStore((store) => store.setOrganisationDetail)
  const ccId = useCenterStore((store) => store.ccId)
  const [calenderToggleEnabled, setCalenderToggleEnabled] = useState(null)
  const [pGWToggleEnabled, setPGWToggleEnabled] = useState(null)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const isAdminUser = currentUser && currentUser.is_admin
  const [timeZones, setTimeZones] = useState([])
  const [isOrganisationConfigured, setIsOrganisationConfigured] = useState(false)
  const [selectedTimeZone, setSelectedTimeZone] = useState('')

  const fetchCC = async () => {
    try {
      const response = await getCalibrationCenter({
        ccid: ccId
      })
      setCenterDetail(response.data)
      setCalenderToggleEnabled(!!response.data?.is_scheduler_enabled)
      setPGWToggleEnabled(!!response.data?.is_pgw_enabled)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'schedulerSetting', ccId: ccId } })
    }
  }
  const fetchOrganisationDetailsByCcId = async () => {
    setLoading(true)
    try {
      const orgResponse = await getOrganisationByReferenceId({ ccId })
      if (orgResponse?.status === 200 && orgResponse?.data?.id) {
        setSelectedTimeZone(orgResponse.data.timezone_at)
        setOrganisationDetail(orgResponse.data)
        setIsOrganisationConfigured(true)
      } else {
        setIsOrganisationConfigured(false)
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'schedulerSettings', method: 'getOrganisationByReferenceId' }
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchTimeZones = async () => {
    try {
      const timeZoneResponse = await getTimeZones({
        ccId: ccId
      })
      if (timeZoneResponse?.data) {
        const timeZones = timeZoneResponse?.data.map((tz) => {
          return { id: tz.timezone_offset, value: tz.name, timezone_offset_value: tz.timezone_offset_value }
        })
        setTimeZones(timeZones)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'schedulerSetting', ccId: ccId } })
    }
  }
  useEffect(() => {
    if (ccId > 0) {
      fetchCC()
      fetchTimeZones()
      fetchOrganisationDetailsByCcId()
    }
  }, [ccId])

  const updatePGWEnabled = async (isEnabled) => {
    try {
      setLoading(true)
      await updateEnablePGWForCalibrationCenter(ccId, isEnabled)
      fetchCC()
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId, isEnabled } })
    } finally {
      setLoading(false)
    }
  }

  const updateSchedulerEnabled = async (isEnabled) => {
    try {
      setLoading(true)
      await updateEnableSchedulerForCalibrationCenter(ccId, isEnabled)
      fetchCC()
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId, isEnabled } })
    } finally {
      setLoading(false)
    }
  }

  const handlePGWToggle = (e) => {
    const value = e.target.value === 'Yes'
    setPGWToggleEnabled(value)
    updatePGWEnabled(value)
  }
  const handleCalendarToggle = (e) => {
    const value = e.target.value === 'Yes'
    setCalenderToggleEnabled(value)
    updateSchedulerEnabled(value)
  }

  const timeZoneChangeHandler = async (event) => {
    try {
      setLoading(true)
      await updateTimeZoneOffsetForOrganisation(ccId, event.target.value)
      fetchOrganisationDetailsByCcId()
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'generalSettings', ccId: ccId, timeZoneOffset: event.target.value } })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <AdasTypography component='span' fontWeight='bold' sx={{ marginTop: '20px' }} variant='h6'>
        Scheduler Settings (Admin Console)
      </AdasTypography>
      <AdasCard
        sx={{
          margin: '17px 0px',
          padding: '16px 16px'
        }}
      >
        <AdasBox>
          {/* Calendar Section */}
          <AdasTypography sx={{ fontWeight: '600', marginBottom: '5px' }}>Calendar</AdasTypography>
          <AdasGrid container spacing={2} alignItems='center'>
            {isAdminUser ? (
              <AdasGrid container alignItems='center' sx={{ marginTop: '15px', marginLeft: '25px' }}>
                <AdasGrid item xs={12} sm={8} md={9}>
                  <AdasTypography variant='body2'>
                    Click &apos;Yes&apos; to activate Scheduler and Calendar in Car ADAS Plus for the calibration center. Once enabled,
                    Scheduler will display in left navigation bar and external scheduling link will be active..
                  </AdasTypography>
                </AdasGrid>
                <AdasGrid item xs={12} sm={4} md={3} container justifyContent='flex-end' alignItems='center'>
                  <AdasButtonGroup
                    type='toggleButton'
                    value={calenderToggleEnabled ? 'Yes' : 'No'}
                    exclusive
                    onChange={handleCalendarToggle}
                    aria-label='opt-in'
                    name='optIn'
                    sx={{ width: { xs: '100%', sm: 'auto' }, mt: { xs: '10px', sm: 0 } }}
                  >
                    <AdasButton
                      buttonType='toggleButton'
                      value='Yes'
                      color='primary'
                      aria-label='Yes'
                      sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
                    >
                      Yes
                    </AdasButton>
                    <AdasButton
                      buttonType='toggleButton'
                      value='No'
                      color='primary'
                      aria-label='No'
                      sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
                    >
                      No
                    </AdasButton>
                  </AdasButtonGroup>
                </AdasGrid>
              </AdasGrid>
            ) : null}
          </AdasGrid>
          {calenderToggleEnabled && (
            <>
              <AdasTypography sx={{ fontWeight: '600', marginTop: '10px' }}>Car ADAS PGW Glass Referral Network</AdasTypography>

              <AdasGrid container spacing={2} alignItems='center'>
                {isAdminUser ? (
                  <AdasGrid container alignItems='center' sx={{ marginTop: '15px', marginLeft: '25px' }}>
                    <AdasGrid item xs={12} sm={8} md={8}>
                      <AdasTypography variant='body2'>
                        Calibration Center will participate in referral network and receive calibration appointments scheduled from PGW
                      </AdasTypography>
                    </AdasGrid>
                    <AdasGrid item xs={12} sm={4} md={4} container justifyContent='flex-end' alignItems='center'>
                      <AdasButtonGroup
                        type='toggleButton'
                        value={pGWToggleEnabled ? 'Yes' : 'No'}
                        exclusive
                        onChange={handlePGWToggle}
                        aria-label='opt-in'
                        name='optIn'
                        sx={{ width: { xs: '100%', sm: 'auto' }, mt: { xs: '10px', sm: 0 } }}
                      >
                        <AdasButton
                          buttonType='toggleButton'
                          value='Yes'
                          color='primary'
                          aria-label='Yes'
                          sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
                        >
                          Yes
                        </AdasButton>
                        <AdasButton
                          buttonType='toggleButton'
                          value='No'
                          color='primary'
                          aria-label='No'
                          sx={{ padding: '5px 10px', flex: { xs: 1, sm: 'none' } }}
                        >
                          No
                        </AdasButton>
                      </AdasButtonGroup>
                    </AdasGrid>
                  </AdasGrid>
                ) : null}
              </AdasGrid>
            </>
          )}
          {isAdminUser && isOrganisationConfigured && calenderToggleEnabled && (
            <>
              <AdasTypography sx={{ fontWeight: '600', marginTop: '10px' }}>Time Zone</AdasTypography>
              <AdasGrid container spacing={2} alignItems='center' sx={{ marginBottom: '10px' }}>
                <AdasGrid container alignItems='center' sx={{ marginTop: '15px', marginLeft: '25px' }}>
                  <AdasGrid item xs={12} sm={8} md={8}>
                    <AdasTypography variant='body2'>Select the calibration center time zone</AdasTypography>
                  </AdasGrid>
                  <AdasGrid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    container
                    justifyContent='flex-end'
                    alignItems='center'
                    sx={{ marginTop: { xs: '15px', md: '0px' } }}
                  >
                    <AdasFormControl fullWidth>
                      <AdasInputLabel id='timeZoneLabelId'>Time Zone</AdasInputLabel>
                      <AdasSelect
                        labelId='timeZoneLabelId'
                        id='timeZone'
                        value={selectedTimeZone}
                        label='Time Zone'
                        onChange={timeZoneChangeHandler}
                        options={timeZones || []}
                      />
                    </AdasFormControl>
                  </AdasGrid>
                </AdasGrid>
              </AdasGrid>
            </>
          )}
        </AdasBox>
      </AdasCard>
    </>
  )
}
