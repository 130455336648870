import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasTooltip = ({ title, disableHoverListener, children }) => {
  return (
    <Tooltip title={title} disableHoverListener={disableHoverListener}>
      {children}
    </Tooltip>
  )
}

AdasTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
