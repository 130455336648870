import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import theme from 'theme/theme'
import { NoteForm } from '@components/note'
import { AdasBox, AdasButton, AdasPaper, AdasTabs, AdasTypography } from '@components/wrapper-components'

export const Note = (props) => {
  const { submit, existingInternalNote, existingExternalNote, allowEdit = true, noBottomBorder = false } = props
  const [noteType, setNoteType] = useState('external')
  const [isAddNote, setIsAddNote] = useState(false)

  const existingNote = noteType === 'external' ? existingExternalNote : existingInternalNote
  const maxLength = noteType === 'external' ? 1000 : 500
  const label = noteType === 'external' ? 'Notes Printed on Invoice' : 'Notes for Technician'
  const tabs = [
    { label: 'External Note', value: 'external' },
    { label: 'Internal Note', value: 'internal' }
  ]

  const submitHandler = useCallback(
    (value) => {
      const noteData = {
        notes_external: existingExternalNote,
        notes_internal: existingInternalNote
      }
      if (noteType === 'external') {
        noteData.notes_external = value
      } else {
        noteData.notes_internal = value
      }
      submit({ note: noteData, noteType })
      setIsAddNote(false)
    },
    [existingExternalNote, existingInternalNote, noteType, submit]
  )

  const onTabChange = useCallback((e, value) => {
    setNoteType(value)
    setIsAddNote(false)
  }, [])

  return (
    <AdasBox sx={{ borderBottom: noBottomBorder ? 0 : 1, borderColor: 'divider' }}>
      <AdasTabs tabs={tabs} value={noteType} onChange={onTabChange} aria-label='note listing tab' />
      <AdasPaper
        variant='outlined'
        sx={{
          marginBottom: '20px',
          backgroundColor: theme.palette.background.light
        }}
      >
        {!existingNote && !isAddNote ? (
          <AdasBox
            sx={{
              height: '112px',
              margin: '20px',
              display: 'flex'
            }}
          >
            {' '}
            <AdasTypography sx={{ margin: 'auto', textAlign: 'center' }} variant='body2'>
              No Note Added
              <AdasButton
                sx={{ display: 'block', marginTop: '20px' }}
                variant='outlined'
                aria-label='add note'
                color='primary'
                onClick={() => setIsAddNote(true)}
                disabled={!allowEdit}
              >
                Add Note
              </AdasButton>
            </AdasTypography>
          </AdasBox>
        ) : (
          <NoteForm
            noteType={noteType}
            existingNote={existingNote}
            cancel={() => setIsAddNote(false)}
            submit={submitHandler}
            label={label}
            allowEdit={allowEdit}
            maxLength={maxLength}
            txAreaBgColor={'white'}
          />
        )}
      </AdasPaper>
    </AdasBox>
  )
}

Note.propTypes = {
  submit: PropTypes.func.isRequired,
  existingInternalNote: PropTypes.string,
  existingExternalNote: PropTypes.string,
  allowEdit: PropTypes.bool,
  noBottomBorder: PropTypes.bool
}
