import { useCenterStore, useUserStore } from '@caradasstore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { CaradasLogoWrapper } from '@components/logo-wrapper'
import { AdasBox, AdasButton, AdasContainer, AdasDivider, AdasPaper, AdasStack, AdasTypography } from '@components/wrapper-components'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getCalibrationCenter } from '../../api/api'
import { REDIRECT_URLS } from '../../constants'
import { ccidFormatter, isEmpty } from '../../utils/utils'
import { getUserList } from '../../api/api'

export const HomePage = () => {
  const history = useHistory()
  const ccId = useCenterStore((store) => store.ccId)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const setUserList = useCenterStore((store) => store.setUserList)
  const userEmail = useUserStore((store) => store.userEmail)
  const setCurrentUser = useUserStore((store) => store.setCurrentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)

  useEffect(() => {
    const fetchCalibrationCenter = async (id, tok) => {
      const ccResponse = await getCalibrationCenter({
        token: tok,
        ccid: id
      })
      setLoading(false)
      if (ccResponse.status === 200) {
        setCenterDetail(ccResponse.data)
      }
    }
    if (ccId && isEmpty(centerDetail)) {
      setLoading(true)
      fetchCalibrationCenter(ccId)
    }
  }, [ccId, setCenterDetail, centerDetail])

  useEffect(() => {
    const userListResponse = getUserList({
      ccid: centerDetail.id ? centerDetail.id : ccId
    })
    userListResponse.then((response) => {
      if (response?.status === 200) {
        setUserList(response.data)
        if (userEmail) {
          const user = response.data.find((item) => item.email?.toLowerCase() === userEmail?.toLowerCase())
          if (user) {
            setCurrentUser(user)
          }
        }
      }
    })
  }, [setCurrentUser, setUserList, userEmail, ccId, centerDetail])

  const redirectToNewWorkOrder = () => {
    history.push(REDIRECT_URLS.NEW_WORK_ORDER)
  }

  const redirectToWorkOrders = () => {
    history.push('/workorders')
  }

  const redirectToClients = () => {
    history.push('/clients')
  }

  const redirectToQuotes = () => {
    history.push('/quotes')
  }

  const redirectToInvoices = () => {
    history.push('/invoices')
  }
  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper elevation={0} sx={{ padding: '20px' }}>
        <AdasBox sx={{ backgroundColor: 'white' }}>
          <AdasBox>
            <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <AdasTypography sx={{ padding: '1rem' }}>{ccidFormatter(isEmpty(centerDetail) ? ccId : centerDetail.id)}</AdasTypography>
              <AdasTypography sx={{ padding: '1rem' }}>{centerDetail?.name}</AdasTypography>
            </AdasBox>
          </AdasBox>
          <CaradasLogoWrapper size='lg' image={centerDetail.image}>
            <AdasBox>
              <AdasButton fullWidth color='primary' variant='contained' sx={{ marginBottom: '10px' }} onClick={redirectToNewWorkOrder}>
                CREATE NEW WORK ORDER
              </AdasButton>
              <AdasButton fullWidth variant='outlined' onClick={redirectToWorkOrders}>
                VIEW WORK ORDERS
              </AdasButton>
            </AdasBox>
            <AdasBox sx={{ margin: '10px 0px' }}>
              <AdasStack
                direction='row'
                spacing={2}
                divider={<AdasDivider orientation='vertical' flexItem sx={{ backgroundColor: '#0071E3' }} />}
              >
                <AdasButton startIcon={<CalculateOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectToQuotes}>
                  Quotes
                </AdasButton>
                <AdasButton startIcon={<BusinessOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectToClients}>
                  CLIENTS
                </AdasButton>
                <AdasButton startIcon={<AttachMoneyOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectToInvoices}>
                  INVOICES
                </AdasButton>
              </AdasStack>
            </AdasBox>
          </CaradasLogoWrapper>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
