import { Menu } from '@mui/material'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'

export const AdasMenu = ({ options, handleMenuItemClick, open, onClose, ...props }) => (
  <Menu open={open} onClose={onClose} {...props}>
    {options.map(
      (option, index) =>
        !!option.visible && (
          <MenuItem disabled={option.disabled} key={index} onClick={() => handleMenuItemClick(option.action)}>
            {option.label}
          </MenuItem>
        )
    )}
  </Menu>
)

AdasMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool,
      action: PropTypes.func,
      label: PropTypes.string
    })
  ),
  handleMenuItemClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
