import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SearchOrAddData } from '@components/common'
import CloseIcon from '@mui/icons-material/Close'

import { ContactForm, ContactInfo } from '@components/client'
import { AdasBox, AdasButton, AdasDrawer, AdasPaper, AdasTable, AdasTypography } from '@components/wrapper-components'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { CaradasSnackbar } from '@components/snackbar'
import { updateContact, getClientContacts } from '../../../api/api'
import { GENERIC_MESSAGES, CLIENT_CONTACT_LIST_HEADERS, TABLE_HEADER_STYLE } from '../../../constants'
import { Logger } from '../../../logger'
import theme from 'theme/theme'

export const ClientContactList = (props) => {
  const { clientId } = props
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [originalContacts, setOriginalContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [sortedContacts, setSortedContacts] = useState([])
  const [isAddContact, setIsAddContact] = useState(false)
  const [isViewContact, setIsViewContact] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})
  const deletedContactRef = useRef()
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [searchInput, setSearchInput] = useState('')
  const [sort, setSort] = useState('ASC')

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
  }

  const onSaveContactSuccessHandler = () => {
    if (isAddContact) {
      setIsAddContact(false)
    }
    fetchClientContactDetails()
  }

  const onDeleteContactHandler = (props) => {
    deletedContactRef.current = props
    fetchClientContactDetails()
    setOpenSnackBar(true)
    setIsViewContact(false)
  }

  const undoDeleteContactHandler = async () => {
    try {
      const updateContactResponse = await updateContact({
        data: deletedContactRef.current.data
      })

      if (updateContactResponse.status === 200) {
        fetchClientContactDetails()
      }
      setOpenSnackBar(false)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ClientInfo', method: 'undoDeleteContactHandler' } })
    }
  }

  const fetchClientContactDetails = useCallback(async () => {
    setLoading(true)
    try {
      const clientContactsResponse = await getClientContacts({ clientId })
      if (clientContactsResponse?.status === 200) {
        setOriginalContacts(clientContactsResponse.data)
        setFilteredContacts(clientContactsResponse.data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { clientId } })
    } finally {
      setLoading(false)
    }
  }, [clientId])

  useEffect(() => {
    const performSearch = () => {
      const filtered = originalContacts.filter((contact) =>
        Object.values(contact).some((value) => String(value).toLowerCase().includes(searchInput.toLowerCase()))
      )
      setFilteredContacts(filtered)
    }

    const timeoutId = setTimeout(performSearch, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchInput, originalContacts])

  useEffect(() => {
    const sorted = [...filteredContacts].sort((a, b) => {
      if (sort === 'ASC') return a.name?.localeCompare(b.name)
      return b.name?.localeCompare(a.name)
    })
    setSortedContacts(sorted)
  }, [filteredContacts, sort])

  const clickSortHandler = useCallback((id) => {
    if (id === 'name') {
      setSort((prevSort) => (prevSort === 'ASC' ? 'DESC' : 'ASC'))
    }
  }, [])

  useEffect(() => {
    fetchClientContactDetails()
  }, [fetchClientContactDetails])

  const contactInfoHandler = (props) => {
    setIsViewContact(true)
    setSelectedContact(props)
  }

  return (
    <AdasBox>
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <SearchOrAddData searchInput={searchInput} searchInputHandler={searchInputHandler} onAddClick={() => setIsAddContact(true)} />
        {sortedContacts.length > 0 && (
          <AdasTable
            containerProps={{ component: AdasPaper }}
            tableProps={{
              sx: {
                marginTop: '20px',
                marginBottom: '20px',
                '.MuiTableCell-root': { border: 'none', padding: '2px 20px' },
                overflowX: 'scroll'
              },
              'aria-label': 'contacts'
            }}
            columns={CLIENT_CONTACT_LIST_HEADERS}
            sortField='name'
            sortDirection={sort}
            onSort={clickSortHandler}
            headerStyle={TABLE_HEADER_STYLE}
            data={sortedContacts}
            onSelect={contactInfoHandler}
          />
        )}
      </AdasPaper>
      <AdasDrawer anchor={'right'} open={isViewContact} onClose={() => setIsViewContact(false)}>
        <AdasBox className='drawer-title-container'>
          <AdasTypography variant='h6'>Contact</AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              padding: '2px',
              cursor: 'pointer',
              color: theme.palette.info.main
            }}
            onClick={() => setIsViewContact(false)}
          >
            <CloseIcon fontSize='small' />
          </AdasButton>
        </AdasBox>
        <ContactInfo contact={selectedContact} onSaveSuccess={onSaveContactSuccessHandler} deleteContactHandler={onDeleteContactHandler} />
      </AdasDrawer>
      <AdasDrawer anchor={'right'} open={isAddContact} onClose={() => setIsAddContact(false)}>
        <AdasBox className='drawer-title-container'>
          <AdasTypography variant='h6'>Contact</AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              padding: '2px',
              cursor: 'pointer',
              color: theme.palette.info.main
            }}
            onClick={() => setIsAddContact(false)}
          >
            <CloseIcon fontSize='small' />
          </AdasButton>
        </AdasBox>
        <ContactForm
          isAddContact={isAddContact}
          deleteContactHandler={onDeleteContactHandler}
          contactData={{ client_id: clientId }}
          cancel={() => setIsAddContact(false)}
          onSaveSuccess={onSaveContactSuccessHandler}
        />
      </AdasDrawer>
      <AdasBox>
        <CaradasSnackbar
          open={openSnackBar}
          message={GENERIC_MESSAGES.CONTACT_DELETED}
          onClose={setOpenSnackBar}
          onUndo={undoDeleteContactHandler}
        />
      </AdasBox>
    </AdasBox>
  )
}

ClientContactList.propTypes = {
  clientId: PropTypes.string
}
