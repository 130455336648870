import { ClickAwayListener, Collapse, MenuList, MenuItem, alpha } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { AdasBox, AdasDivider, AdasListItemIcon, AdasListItemText } from '@components/wrapper-components'

// eslint-disable-next-line react/display-name
export const AdasMenuList = React.forwardRef(({ items = [], onClickAway, variant, open, pathName, onItemClick, ...rest }, ref) => {
  const theme = useTheme()
  const [openItems, setOpenItems] = useState({})

  const handleClickAway = () => {
    if (onClickAway && variant === 'withClickAway') {
      onClickAway()
    }
  }

  const handleItemClick = (onClick) => {
    if (onClick) {
      onClick()
    }
  }

  const handleItemClickWithOutClickAway = (item) => {
    if (item.children) {
      setOpenItems((prevOpenItems) => ({
        ...prevOpenItems,
        [item.label]: !prevOpenItems[item.label]
      }))
    } else if (item.path) {
      onItemClick(item.path)
    }
  }

  return (
    <>
      {variant === 'withClickAway' ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <MenuList ref={ref} {...rest}>
            {items.map((item, index) => (
              <MenuItem key={index} onClick={() => handleItemClick(item.onClick)}>
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      ) : (
        <MenuList>
          {items.map((item, index) => (
            <AdasBox component='span' key={index}>
              {item.children && (
                <AdasDivider variant='middle' sx={{ my: '10px', backgroundColor: alpha(theme.palette.primary.main, 0.5) }} />
              )}
              <MenuItem onClick={() => handleItemClickWithOutClickAway(item)}>
                <AdasListItemIcon>
                  <item.icon
                    sx={{
                      fontWeight: '600',
                      fill: item.children
                        ? 'unset'
                        : (pathName === '/' && item.childPathPrefix === '/') ||
                            (item.childPathPrefix !== '/' && pathName.includes(item.childPathPrefix))
                          ? `${theme.palette.primary.main}`
                          : 'unset'
                    }}
                    fontSize='small'
                  />
                </AdasListItemIcon>
                {open && (
                  <AdasListItemText
                    key={'text' + item.label}
                    disableTypography={true}
                    sx={{
                      maxWidth: 'fit-content',
                      fontSize: '0.90rem',
                      fontWeight: '600',
                      color: item.children
                        ? 'unset'
                        : (pathName === '/' && item.childPathPrefix === '/') ||
                            (item.childPathPrefix !== '/' && pathName.includes(item.childPathPrefix))
                          ? `${theme.palette.primary.main}`
                          : 'unset'
                    }}
                  >
                    <AdasBox sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.label}</AdasBox>
                  </AdasListItemText>
                )}
                {item.children
                  ? open && (openItems[item.label] ? <ExpandLess color='primary' sx={{ float: 'left' }} /> : <ExpandMore />)
                  : null}
              </MenuItem>
              {item.children && (
                <Collapse in={openItems[item.label]} timeout='auto' unmountOnExit>
                  <AdasBox sx={{ pl: open ? 2 : 0 }}>
                    <AdasMenuList
                      variant='withoutClickAway'
                      onItemClick={onItemClick}
                      pathName={pathName}
                      open={open}
                      items={item.children}
                    />
                  </AdasBox>
                </Collapse>
              )}
            </AdasBox>
          ))}
        </MenuList>
      )}
    </>
  )
})

AdasMenuList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      onClick: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  onClickAway: PropTypes.func,
  variant: PropTypes.oneOf(['withClickAway', 'withoutClickAway'])
}
