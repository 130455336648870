import CloseIcon from '@mui/icons-material/Close'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { StatusInfo } from '@components/status-info'

import { ClientForm } from '@components/client'
import { AdasBox, AdasButton, AdasCard, AdasDrawer, AdasDivider, AdasGrid, AdasTypography } from '@components/wrapper-components'
import { getClient } from '../../../api/api'
import { phoneNumberFormat } from '../../../utils/utils'
import { DATE_FORMATS, LABEL_DISPLAY, CLIENT_TYPE_ENUM } from '../../../constants'
import { formatDate } from '../../../utils/utils'
import { Logger } from '../../../logger'
import theme from 'theme/theme'

export const ClientInfo = (props) => {
  const { clientId, existingClients = [] } = props
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [clientInfo, setClientInfo] = useState({})
  const [isClientEdit, setIsClientEdit] = useState(false)
  const labelStyle = {
    margin: '8px 0px',
    fontWeight: '600'
  }
  const onSaveClientSuccessHandler = () => {
    fetchClientDetails()
  }

  const fetchClientDetails = useCallback(async () => {
    setLoading(true)
    try {
      const clientResponse = await getClient({ clientId })
      if (clientResponse?.status === 200) {
        setClientInfo(clientResponse.data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { clientId } })
    } finally {
      setLoading(false)
    }
  }, [clientId])

  useEffect(() => {
    fetchClientDetails()
  }, [fetchClientDetails])

  return (
    <AdasBox>
      <AdasCard
        sx={{
          margin: '17px 0px',
          padding: '20px',
          backgroundColor: '#FFFFFF'
        }}
      >
        <AdasGrid container spacing={2} sx={{ overflow: 'auto' }}>
          <AdasGrid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <AdasTypography sx={{ fontSize: '1.25rem', fontWeight: '600' }} variant='subHeader'>
              {clientInfo.name}
            </AdasTypography>
            <AdasButton
              startIcon={<ModeEditOutlineOutlinedIcon />}
              aria-label='Cancel'
              color='primary'
              onClick={() => setIsClientEdit(true)}
            >
              Edit
            </AdasButton>
          </AdasGrid>

          <AdasGrid container item xs={12} spacing={3}>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                {LABEL_DISPLAY.MONTHLY_VEHICLECOUNT_LABEL}
              </AdasTypography>
              <AdasTypography
                sx={{
                  paddingLeft: !clientInfo.vehicle_count ? '20%' : '0'
                }}
              >
                {clientInfo.vehicle_count || '-'}
              </AdasTypography>
            </AdasGrid>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Type
              </AdasTypography>
              <AdasTypography>{CLIENT_TYPE_ENUM[clientInfo.type] || '-'}</AdasTypography>
            </AdasGrid>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Last Modified
              </AdasTypography>
              <AdasTypography
                sx={{
                  paddingLeft: !clientInfo.updated_at && !clientInfo.created_at ? '15%' : '0'
                }}
              >
                {formatDate(clientInfo.updated_at || clientInfo.created_at, DATE_FORMATS.ISO_DATE_STRING) || '-'}
              </AdasTypography>
            </AdasGrid>

            <AdasGrid item xs={3} sx={{ marginLeft: { xs: 'left', sm: 'auto' }, textAlign: 'end' }}>
              <AdasTypography sx={{ textAlign: 'end', ...labelStyle }} variant='subtitle2'>
                Status &nbsp;&nbsp;
              </AdasTypography>
              <StatusInfo label={clientInfo.is_active ? 'Active' : 'Inactive'} color={clientInfo.is_active ? 'primary' : 'secondary'} />
            </AdasGrid>
          </AdasGrid>

          <AdasDivider orientation='horizontal' flexItem sx={{ marginTop: '1rem', width: '100%' }} />

          <AdasGrid item xs={12}>
            <AdasTypography sx={labelStyle} variant='subtitle2'>
              Address
            </AdasTypography>
            <AdasTypography>{clientInfo.address1}</AdasTypography>
          </AdasGrid>

          <AdasGrid container item xs={12} spacing={3}>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                City
              </AdasTypography>
              <AdasTypography>{clientInfo.city}</AdasTypography>
            </AdasGrid>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                State
              </AdasTypography>
              <AdasTypography>{clientInfo.state}</AdasTypography>
            </AdasGrid>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Zip
              </AdasTypography>
              <AdasTypography>{clientInfo.zip}</AdasTypography>
            </AdasGrid>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Country
              </AdasTypography>
              <AdasTypography>{clientInfo.country}</AdasTypography>
            </AdasGrid>
          </AdasGrid>

          <AdasGrid item xs={12} container spacing={3}>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Phone
              </AdasTypography>
              <AdasTypography>
                <a href={`tel:${clientInfo.phone}`}>{phoneNumberFormat(clientInfo.phone)}</a>
              </AdasTypography>
            </AdasGrid>
            <AdasGrid item xs={6} sm={2}>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Email
              </AdasTypography>
              <AdasTypography>
                <a href={`mailto:${clientInfo.email}`}>{clientInfo.email}</a>
              </AdasTypography>
            </AdasGrid>
          </AdasGrid>
          <AdasDivider orientation='horizontal' flexItem sx={{ marginTop: '1rem', width: '100%' }} />

          <AdasGrid item xs={12} sx={{ marginBottom: '20px' }}>
            <AdasTypography sx={labelStyle} variant='subtitle2'>
              Notes
            </AdasTypography>
            <AdasTypography
              sx={{
                paddingLeft: !clientInfo.notes ? '1.5%' : '0'
              }}
            >
              {clientInfo.notes || '-'}
            </AdasTypography>
          </AdasGrid>
        </AdasGrid>
      </AdasCard>

      <AdasDrawer anchor={'right'} open={isClientEdit} onClose={() => setIsClientEdit(false)}>
        <AdasBox className='drawer-title-container'>
          <AdasTypography variant='h6'>Client Info</AdasTypography>
          <AdasButton
            buttonType='iconButton'
            sx={{
              padding: '2px',
              cursor: 'pointer',
              color: theme.palette.info.main
            }}
            onClick={() => setIsClientEdit(false)}
          >
            <CloseIcon fontSize='small' />
          </AdasButton>
        </AdasBox>

        <ClientForm
          clientData={clientInfo}
          close={() => setIsClientEdit(false)}
          onSaveSuccess={onSaveClientSuccessHandler}
          existingClients={existingClients.filter((name) => name !== clientInfo.name)}
        />
      </AdasDrawer>
    </AdasBox>
  )
}

ClientInfo.propTypes = {
  clientId: PropTypes.string,
  existingClients: PropTypes.array
}
