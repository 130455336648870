import React, { useEffect, useState, useCallback } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import { AdasBox, AdasButton, AdasContainer, AdasDrawer, AdasPaper, AdasTabs, AdasTypography } from '@components/wrapper-components'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { ProductForm, ProductInfo, ProductList } from '@components/product'
import { Logger } from '../../logger'
import { getCalibrationList, getServiceList, getPartsList, getLabourList } from '../../api/api'
import theme from 'theme/theme'
import { useUserStore } from '@caradasstore/UserStore'
import { ImportCSVComponent } from '@components/import-form'
import { uploadProductCSV, validateProductCSV } from '../../api/api'
import { GENERIC_MESSAGES } from 'constants'
import { downloadAsFile } from 'utils/utils'
import { PRODUCT_LIST_HEADERS, SERVICE_LIST_HEADERS, PARTS_LIST_HEADERS, LABOUR_LIST_HEADERS } from 'constants'
import useBooleanStore from '@caradasstore/BooleanStore'

const IMPORT_CLIENT_STEPPER = [0, 1]

const DummyData = [
  {
    id: 723,
    name: 'Diagnostic Labour Service',
    price: 240,
    description: 'Validate all safety systems work, in unison, as engineered, in town and highway speeds.',
    calibration_center_id: 1,
    itemNum: 'CAL0037',
    category: 'LABOUR',
    is_defaulted: true,
    is_active: true,
    created_at: '2024-11-14T09:46:58.507261Z',
    updated_at: '2024-11-14T09:46:58.507265Z'
  },
  {
    id: 716,
    name: 'Diagnostic Labour Service II',
    price: 399,
    description: '',
    calibration_center_id: 1,
    itemNum: 'CAL0030',
    category: 'LABOUR',
    is_defaulted: false,
    is_active: false,
    created_at: '2024-11-14T09:16:56.650551Z',
    updated_at: '2024-11-19T12:29:43.455192Z'
  },
  {
    id: 1251,
    name: 'Diagnostic Labour Service III',
    price: 200,
    description: 'Photo Lidar Tech',
    calibration_center_id: 1,
    itemNum: 'CAL0060',
    category: 'LABOUR',
    is_defaulted: null,
    is_active: true,
    created_at: '2024-11-21T09:35:32.474660Z',
    updated_at: null
  },
  {
    id: 672,
    name: 'Technician',
    price: 100,
    description: null,
    calibration_center_id: 1,
    itemNum: 'CAL0026',
    category: 'LABOUR',
    is_defaulted: null,
    is_active: true,
    created_at: '2024-10-30T12:23:26.282832Z',
    updated_at: null
  }
]
const DummyData2 = [
  {
    id: 722,
    name: 'Wire Harness - BMW',
    price: 240,
    description: 'Validate all safety systems work, in unison, as engineered, in town and highway speeds.',
    calibration_center_id: 1,
    itemNum: 'CAL0036',
    category: 'PARTS',
    is_defaulted: true,
    is_active: true,
    created_at: '2024-11-14T09:22:06.566444Z',
    updated_at: '2024-11-15T15:43:08.246826Z'
  },
  {
    id: 719,
    name: 'Subaru Camera',
    price: 185,
    description: '',
    calibration_center_id: 1,
    itemNum: 'CAL0033',
    category: 'PARTS',
    is_defaulted: true,
    is_active: true,
    created_at: '2024-11-14T09:16:56.654631Z',
    updated_at: '2024-11-14T09:16:56.654633Z'
  },
  {
    id: 15,
    name: 'Side Mirror',
    price: 185,
    description: '',
    calibration_center_id: 1,
    itemNum: 'CAL0015',
    category: 'PARTS',
    is_defaulted: false,
    is_active: true,
    created_at: '2024-05-09T13:10:30.868275Z',
    updated_at: null
  },
  {
    id: 16,
    name: 'Wire Harness - Audi',
    price: 260,
    description: '',
    calibration_center_id: 1,
    itemNum: 'CAL0016',
    category: 'PARTS',
    is_defaulted: false,
    is_active: true,
    created_at: '2024-05-09T13:10:30.868275Z',
    updated_at: null
  }
]

export const ProductsPage = () => {
  const [calibrationList, setCalibrationList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [partsList, setPartsList] = useState([])
  const [labourList, setLabourList] = useState([])
  const [currentTab, setCurrentTab] = React.useState('calibrations')
  const [isProductInfo, setIsProductInfo] = useState(false)
  const [isAddProduct, setIsAddProduct] = useState(false)
  const [productData, setProductData] = useState({})
  const ccId = useCenterStore((store) => store.ccId)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const [openImportForm, setOpenImportForm] = useState(false)
  const [activeStep, setActiveStep] = useState(IMPORT_CLIENT_STEPPER[0])
  const [file, setFile] = useState(null)
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState(null)
  const [csvValid, setCsvValid] = useState(true)
  const [CSVValidationResponse, setCSVValidationResponse] = useState(null)
  const [CSVUploadResponse, setCSVUploadResponse] = useState(null)
  const { isToggled } = useBooleanStore()

  const allTabs = [
    { label: 'Calibrations', value: 'calibrations' },
    { label: 'Services', value: 'services' },
    { label: 'Parts', value: 'parts' },
    { label: 'Labour', value: 'labour' }
  ]

  // Filter tabs based on isToggled
  const tabs = allTabs.filter((tab) => {
    if (!isToggled && (tab.value === 'parts' || tab.value === 'labour')) {
      return false // Exclude "Parts" and "Labour" tabs if not toggled
    }
    return true // Include all other tabs
  })

  const user = useUserStore((store) => store.currentUser)
  const fetchServices = async () => {
    const serviceListResponse = await getServiceList({
      ccid: ccId
    })
    if (serviceListResponse.status === 200) {
      setServiceList(serviceListResponse.data.sort(sortByName))
    }
  }

  const fetchCalibrations = async () => {
    const calibrationListResponse = await getCalibrationList({
      ccid: ccId
    })
    if (calibrationListResponse.status === 200) {
      setCalibrationList(calibrationListResponse.data.sort(sortByName))
    }
  }

  const fetchParts = async () => {
    const partsListResponse = await getPartsList({
      ccid: ccId
    })
    if (partsListResponse.status === 200) {
      setPartsList(partsListResponse.data.sort(sortByName))
    }
    setPartsList(DummyData2)
  }
  const fetchLabour = async () => {
    const labourListResponse = await getLabourList({
      ccid: ccId
    })
    if (labourListResponse.status === 200) {
      setLabourList(labourListResponse.data.sort(sortByName))
    }
    setLabourList(DummyData)
  }

  const changeTab = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const selectProduct = (product) => {
    setProductData(product)
    setIsProductInfo(true)
  }

  const updateProductList = () => {
    const fetchProductList = {
      calibrations: fetchCalibrations,
      services: fetchServices,
      parts: fetchParts,
      labour: fetchLabour
    }

    if (fetchProductList[currentTab]) {
      fetchProductList[currentTab]() // Fetch data for the current tab
    }

    setIsAddProduct(false)
  }

  // const updateProductList = () => {
  //   const fetchProductList = currentTab === 'calibrations' ? fetchCalibrations : fetchServices
  //   fetchProductList()
  //   setIsAddProduct(false)
  // }

  useEffect(() => {
    fetchData()
    /* eslint-disable */
  }, [])

  const fetchData = async () => {
    try {
      setLoading(true)
      await fetchCalibrations()
      await fetchServices()
      fetchParts()
      fetchLabour()
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ProductsPage' } })
    } finally {
      setLoading(false)
    }
  }

  const sortByName = (a, b) => {
    const name1 = a.name.toUpperCase()
    const name2 = b.name.toUpperCase()

    let comparison = 0

    if (name1 > name2) {
      comparison = 1
    } else if (name1 < name2) {
      comparison = -1
    }
    return comparison
  }

  const downloadProducts = useCallback(
    ({ template = false }) => {
      let exportData = template
        ? []
        : [
            ...calibrationList.map((product, index) => [
              '"' + product.name + '"',
              'Calibration',
              '"' + product.category + '"',
              '"' + product.price + '"',
              '"' + product.is_active + '"',
              product.description ? '"' + product.description + '"' : '',
              product.is_defaulted ? '"' + product.is_defaulted + '"' : '',
              '\n'
            ]),
            ...serviceList.map((service, index) => [
              '"' + service.name + '"',
              'Service',
              'Service',
              '"' + service.price + '"',
              '"' + service.is_active + '"',
              service.description ? '"' + service.description + '"' : '',
              service.is_defaulted ? '"' + service.is_defaulted + '"' : '',
              '\n'
            ])
          ]

      exportData = [
        ['Product Name', 'Type', 'Category Name', 'Product Price', 'Status', 'Product Description', 'Default Product\n'],
        ...exportData
      ]
      const fileName = 'product_service_list.csv'
      const value = new Blob(exportData, { type: 'text/plain' })
      downloadAsFile(value, fileName)
    },
    [calibrationList, serviceList]
  )

  const handleValidateCSV = useCallback(async () => {
    if (file instanceof File) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      try {
        const response = await validateProductCSV({ file: formData, ccid: ccId })
        if (response.data.status === 'error') {
          setCsvValid(false)
        } else {
          setCsvValid(true)
        }
        setCSVValidationResponse(response.data.messages)
        setActiveStep((step) => step + 1)
      } catch (error) {
        if (error.status === 400) {
          setCSVValidationResponse(error.response.data.messages)
          setCsvValid(false)
          setActiveStep((step) => step + 1)
        } else {
          Logger.error({ message: error, payload: { file: 'ProductListPage', method: 'handleValidateCSV' } })
        }
      }
    }
  }, [file, validateProductCSV, setActiveStep, setCSVValidationResponse, setCsvValid, ccId])

  const handleCSVUpload = useCallback(async () => {
    if (file instanceof File) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      const response = await uploadProductCSV({ file: formData, ccid: ccId })
      setCSVUploadResponse(response.data.messages)
      setActiveStep((step) => step + 1)
      fetchData()
    }
  }, [file, uploadProductCSV, setActiveStep, ccId])
  const handleClose = useCallback(() => {
    setFile(null)
    setFileUploadErrorMessage(null)
    setActiveStep(IMPORT_CLIENT_STEPPER[0])
    setCSVValidationResponse(null)
    setOpenImportForm(false)
    setCsvValid(true)
  }, [])
  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      {isProductInfo && (
        <AdasDrawer anchor={'right'} open={isProductInfo} onClose={() => setIsProductInfo(false)}>
          <AdasBox>
            <ProductInfo
              product={productData}
              productTypeName={currentTab}
              closeDrawer={() => setIsProductInfo(false)}
              onSaveSuccess={updateProductList}
            />
          </AdasBox>
        </AdasDrawer>
      )}
      {isAddProduct && (
        <AdasDrawer anchor={'right'} open={isAddProduct} onClose={() => setIsAddProduct(false)}>
          <AdasBox className='drawer-title-container'>
            <AdasTypography variant='h6' component='span'>
              Product Info
            </AdasTypography>
            <AdasButton
              buttonType='iconButton'
              sx={{
                padding: '2px',
                cursor: 'pointer',
                color: theme.palette.info.main
              }}
              onClick={() => setIsAddProduct(false)}
            >
              <CloseIcon fontSize='small' />
            </AdasButton>
          </AdasBox>
          <ProductForm
            productData={{ calibration_center_id: ccId }}
            productTypeName={currentTab}
            onSaveSuccess={updateProductList}
            close={() => setIsAddProduct(false)}
          />
        </AdasDrawer>
      )}
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <AdasBox display='flex' justifyContent='space-between' flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
          <AdasTabs tabs={tabs} value={currentTab} onChange={changeTab} aria-label='product listing tab set' />
          {user?.is_admin && (
            <AdasBox
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center'
              }}
            >
              <AdasButton
                variant='contained'
                color='primary'
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={() => setOpenImportForm(true)}
              >
                Import CSV
              </AdasButton>
              <AdasButton
                variant='outlined'
                aria-label='New Work Order'
                color='primary'
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={downloadProducts}
              >
                Export CSV
              </AdasButton>
            </AdasBox>
          )}
        </AdasBox>

        {currentTab === 'calibrations' && (
          <ProductList
            products={calibrationList}
            onAddProduct={setIsAddProduct}
            onSelectProduct={selectProduct}
            columns={PRODUCT_LIST_HEADERS}
          />
        )}
        {currentTab === 'services' && (
          <ProductList
            products={serviceList}
            onAddProduct={setIsAddProduct}
            onSelectProduct={selectProduct}
            columns={SERVICE_LIST_HEADERS}
          />
        )}
        {currentTab === 'parts' && (
          <ProductList products={partsList} onAddProduct={setIsAddProduct} onSelectProduct={selectProduct} columns={PARTS_LIST_HEADERS} />
        )}
        {currentTab === 'labour' && (
          <ProductList products={labourList} onAddProduct={setIsAddProduct} onSelectProduct={selectProduct} columns={LABOUR_LIST_HEADERS} />
        )}
      </AdasPaper>

      <ImportCSVComponent
        open={openImportForm}
        title='Import Products'
        onClose={handleClose}
        downloadCSV={downloadProducts}
        handleValidateCSV={handleValidateCSV}
        handleCSVUpload={handleCSVUpload}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        csvValid={csvValid}
        CSVValidationResponse={CSVValidationResponse}
        file={file}
        IMPORT_CLIENT_STEPPER={IMPORT_CLIENT_STEPPER}
        GENERIC_MESSAGES={GENERIC_MESSAGES}
        CSVUploadResponse={CSVUploadResponse}
        setCsvValid={setCsvValid}
        setFile={setFile}
        fileUploadErrorMessage={fileUploadErrorMessage}
        setFileUploadErrorMessage={setFileUploadErrorMessage}
      />
    </AdasContainer>
  )
}
