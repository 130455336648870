import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  submitPreCalibration,
  getPreCalibration,
  getInvoiceByReferenceNumber,
  getWorkOrderByReferenceNumber,
  getOemPositions,
  getOemPositionStatementsById
} from '../../../api/api'
import { PRE_CALIBRATION_FIELD_MAP } from '../../../variable-maps'
import { FileUploadLongCard } from '@components/file-upload'
import { IdDisplay } from '@components/id-display'
import { PhotoInput } from '@components/photo'
import { StickyFooter } from '@components/sticky-footer'
import {
  AdasBox,
  AdasButton,
  AdasCheckbox,
  AdasContainer,
  AdasFormControl,
  AdasFormControlLabel,
  AdasFormGroup,
  AdasFormLabel,
  AdasInputAdornment,
  AdasInputLabel,
  AdasLink,
  AdasPaper,
  AdasRadioGroup,
  AdasSearchMenu,
  AdasSelect,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../../logger'
import { useHistory } from 'react-router-dom'
import { FILE_UPLOAD, GENERIC_MESSAGES, PARAM_NAMES, REDIRECT_URLS } from '../../../constants'
import { FieldConfigurations, FieldNames, OperationType, StatusEnum } from '../../../enums/enums'
import { useScrollTop } from '../../../utils/useScrollTop'
import { handleDownloadOEMStatementPDF } from 'utils/utils'
import { CircularProgress } from '@mui/material'

export const PreCalibrationPage = (props) => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const { fromInvoice = false } = props
  const [weightNeeded, setWeightNeeded] = useState(0)
  const history = useHistory()
  const setLoading = useLoadingStore((store) => store.setLoading)
  const loading = useLoadingStore((store) => store.loading)
  const [workOrderData, setWorkOrderData] = useState({}) //Object holds WorkOrder/Invoice data
  const [vehicleData, setVehicleData] = useState({})
  const [oemPositions, setOemPositions] = useState([])
  const [statementLoading, setStatementLoading] = useState(false)
  const [oemPositionStatementList, setOemPositionStatementList] = useState([])
  const initialImageState = {
    dashImageUrl: {},
    weightImageUrl1: {},
    driverFrontImageUrl: {},
    driverRearImageUrl: {},
    passengerFrontImageUrl: {},
    passengerRearImageUrl: {},
    driverFrontWheelImageUrl: {},
    driverRearWheelImageUrl: {},
    passengerFrontWheelImageUrl: {},
    passengerRearWheelImageUrl: {},
    alignmentImageUrl: {},
    alignmentImageUrl2: {},
    preCalReportScan: '',
    preCalRepairEstimateScan: '',
    calRequiredReport: '',
    wheelAlignmentScan: '',
    oemProcedureScan: ''
  }
  const [dataUrls, setDataUrls] = useState(initialImageState)

  const [preCalInputValues, setPreCalInputValues] = useState({
    odo_current: '',
    vehicle_license: '',
    fuel_est_per: '',
    is_tire_press: true,
    precal_scan: false,
    is_batt_support: false,
    is_rm_excess_cargo: false,
    is_review_col_est: false,
    isCollisionRepairEstimate: true,
    is_cal_req_report_complete: false,
    is_wheel_align_complete: false,
    is_oem_procedure_complete: false,
    vehicle_make_group_id: '',
    oem_position_id: '',
    oemPositionStatementIds: []
  })

  const {
    odo_current,
    fuel_est_per,
    is_tire_press,
    is_batt_support,
    is_rm_excess_cargo,
    is_oem_procedure_complete,
    precal_scan,
    is_wheel_align_complete
  } = preCalInputValues

  /* eslint-disable no-unused-vars */
  const {
    preCalReportScan,
    preCalRepairEstimateScan,
    calRequiredReport,
    wheelAlignmentScan,
    oemProcedureScan,
    weightImageUrl1,
    alignmentImageUrl,
    alignmentImageUrl2,
    ...requiredImages
  } = dataUrls
  /* eslint-enable no-unused-vars */

  const requiredInputValues = {
    odo_current,
    is_tire_press,
    is_batt_support,
    is_rm_excess_cargo,
    is_oem_procedure_complete,
    precal_scan,
    is_wheel_align_complete,
    ...requiredImages
  }

  const tankCapacity = vehicleData.fuel_capacity_gal
  const orderClosed = StatusEnum[workOrderData.status] === StatusEnum.CLOSED
  const disableEdits = StatusEnum[workOrderData.status] === StatusEnum.CLOSED || fromInvoice

  if (tankCapacity !== 0) {
    requiredInputValues.fuel_est_per = fuel_est_per
  }
  const vehicleName = `${vehicleData.make} ${vehicleData.model} ${vehicleData.trim}`

  const isSubmitEnabled = Object.keys(requiredInputValues).every((key) => {
    if (orderClosed) return false
    if (key === 'fuel_est_per') {
      return requiredInputValues[key] !== ''
    }
    if (key === 'weightImageUrl1' && (tankCapacity === 0 || requiredInputValues.fuel_est_per === 100)) {
      if (typeof requiredInputValues[key] === 'object') {
        return 'id' in requiredInputValues[key]
      } else {
        return requiredInputValues[key] !== '' || !requiredInputValues[key]
      }
    }

    if (typeof requiredInputValues[key] === 'object' && !('id' in requiredInputValues[key])) {
      return false
    }
    return !!requiredInputValues[key]
  })

  const fontCss = {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.15px'
  }
  const classes = {
    subHeading: {
      fontWeight: '700 !important',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0'
    },
    subHeading2: {
      fontWeight: '400',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '266%',
      letterSpacing: '1px',
      color: 'rgba(26, 27, 31, 0.87)',
      paddingBottom: '10px'
    }
  }

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const handleFormInputChange = useCallback((e) => {
    let value =
      e.target.type === 'radio'
        ? e.target.value === 'true'
          ? true
          : false
        : e.target.type === 'checkbox'
          ? e.target.checked
          : e.target.value

    if (e.target.name === 'odo_current') {
      let digitsOnly = value.replace(/[^0-9]/g, '')
      digitsOnly = digitsOnly.slice(0, 7)
      value = digitsOnly
    }
    setPreCalInputValues((prevState) => {
      const updatedState = {
        ...prevState,
        [e.target.name]: value
      }
      return updatedState
    })
  }, [])

  const handleFuelLevel = useCallback(
    (e) => {
      const currentFuelLevel = e.target.value
      const calculatedWeight = ((100 - currentFuelLevel) / 100) * tankCapacity * 6.15
      handleFormInputChange(e)
      setWeightNeeded(calculatedWeight.toFixed(2))
    },
    [handleFormInputChange, tankCapacity]
  )

  const handleOEMPostionChange = (event) => {
    setPreCalInputValues((prevState) => {
      const updatedState = {
        ...prevState,
        oem_position_id: event.target.value,
        oemPositionStatementIds: []
      }
      return updatedState
    })
  }

  const savePreCalibration = useCallback(
    (complete) => {
      const preCalDataSubmit = {
        ...preCalInputValues,
        weight_comp: Number(weightNeeded),
        is_precalibration_complete: complete
      }
      const params = {
        data: preCalDataSubmit,
        workorderId: workOrderData.id
      }

      return submitPreCalibration(params)
    },
    [preCalInputValues, weightNeeded, workOrderData.id]
  )

  const returnToSummaryHandler = useCallback(async () => {
    if (!fromInvoice && !workOrderData.is_precalibration_complete) {
      const response = await savePreCalibration(false)
      if (response.status !== 200) {
        console.log('testing')
      }
    } else if (!fromInvoice && workOrderData.is_precalibration_complete) {
      await savePreCalibration(true)
    }

    history.goBack()
  }, [fromInvoice, history, savePreCalibration, workOrderData])

  const submit = useCallback(async () => {
    const response = await savePreCalibration(true)
    if (response.status === 200) {
      history.goBack()
    }
  }, [fromInvoice, history, savePreCalibration, workOrderData])

  const removeFileHandler = useCallback((fileName) => {
    if (fileName) {
      setDataUrls((prevState) => {
        const dataUrls = {
          ...prevState,
          [fileName]: null
        }
        return dataUrls
      })
    }
  }, [])

  const requestPreCalibration = useCallback(
    async (params) => {
      try {
        setLoading(true)
        const response = await getPreCalibration(params)
        let existingPreCalibrationValues = {}
        if (response.status === 200) {
          const existingPreCalibration = response.data

          Object.keys(existingPreCalibration).forEach((key) => {
            if (existingPreCalibration[key]) {
              existingPreCalibrationValues[key] = existingPreCalibration[key]
            }
          })

          if (existingPreCalibrationValues) {
            setPreCalInputValues((preValues) => {
              return { ...preValues, ...existingPreCalibrationValues }
            })
          }

          if (existingPreCalibrationValues.fuel_est_per) {
            const calculatedWeight = ((100 - existingPreCalibrationValues.fuel_est_per) / 100) * tankCapacity * 6.15
            setWeightNeeded(calculatedWeight.toFixed(2))
          }

          for (const key of Object.keys(PRE_CALIBRATION_FIELD_MAP)) {
            if (existingPreCalibration[key]) {
              const attachmentDetails = existingPreCalibration[key]
              setDataUrls((prevState) => {
                const imageUrls = {
                  ...prevState,
                  [PRE_CALIBRATION_FIELD_MAP[key]]: attachmentDetails
                }
                return imageUrls
              })
            }
          }
        }
      } catch (error) {
        Logger.error({ message: error, payload: { params, path: 'PreCalibrationPage/requestPreCalibration' } })
      } finally {
        setLoading(false)
      }
    },
    [tankCapacity]
  )

  const generatePhotoInputProps = (fieldLocation, labelText, title) => {
    const fieldData = dataUrls[fieldLocation] || {}

    return {
      labelText,
      imageLocation: fieldLocation,
      workorderId: workOrderData.id,
      onUpdate: refresh,
      disabled: disableEdits,
      properties: fieldData,
      imageId: fieldData.id || '',
      img_rotation: fieldData.img_rotation || 0,
      title: title || FieldConfigurations[fieldLocation]?.title
    }
  }

  const refresh = useCallback(
    (refreshedData) => {
      if (refreshedData) {
        const propertyName = refreshedData.imageLocation
        if (refreshedData.operation === OperationType.ROTATE) {
          setDataUrls((prevState) => ({
            ...prevState,
            [propertyName]: {
              ...prevState[propertyName],
              img_rotation: refreshedData.img_rotation
            }
          }))
        }
        if (refreshedData.operation === OperationType.DELETE) {
          setDataUrls((prevState) => ({
            ...prevState,
            [propertyName]: null
          }))
        }
        if (refreshedData.operation === OperationType.RETAKE) {
          setDataUrls((prevState) => ({
            ...prevState,
            [propertyName]: {
              ...prevState[propertyName],
              id: refreshedData.attachmentId
            }
          }))
        }
      } else {
        if (workOrderData.id) {
          const params = {
            workorderId: workOrderData.id
          }
          requestPreCalibration(params)
        }
      }
    },
    [requestPreCalibration, workOrderData.id]
  )

  const FUEL_LEVEL_MAP = [
    { value: 0, name: 'EMPTY' },
    { value: 12, name: '1/8' },
    { value: 25, name: '1/4' },
    { value: 37, name: '3/8' },
    { value: 50, name: '1/2' },
    { value: 62, name: '5/8' },
    { value: 75, name: '3/4' },
    { value: 87, name: '7/8' },
    { value: 100, name: 'FULL' }
  ]

  useEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fromInvoice) {
          let response = await getInvoiceByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            if (!response.data?.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            response.data.invoiceDto.id = response.data.workOrderDto.id // workOrderId
            setWorkOrderData(response.data.invoiceDto)
            setVehicleData(response.data.invoiceDto.vehicle)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        } else {
          let response = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[response.data?.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            setWorkOrderData(response.data.workOrder)
            setVehicleData(response.data.workOrder.vehicle)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
        const oemPositions = await getOemPositions()
        setOemPositions(oemPositions.data)
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber
          }
        })
      }
    }
    if (referenceNumber) fetchData()
  }, [referenceNumber])

  useEffect(() => {
    const fetchOemPositionStatements = async () => {
      try {
        const oemPositionStatementList = await getOemPositionStatementsById(preCalInputValues.oem_position_id)
        setOemPositionStatementList(oemPositionStatementList.data)
      } catch (error) {
        Logger.error({
          message: error,
          payload: { oem_position_id: preCalInputValues.oem_position_id, path: 'PreCalibrationPage/fetchOemPositionStatements' }
        })
      }
    }

    if (preCalInputValues.oem_position_id) {
      fetchOemPositionStatements()
    }
  }, [preCalInputValues.oem_position_id])

  const handleOemStatementSelection = (event) => {
    setPreCalInputValues((prevState) => {
      const id = event.target.value
      let updatedIds = [...prevState.oemPositionStatementIds]
      const index = updatedIds.indexOf(Number(id))
      if (index > -1) {
        // If the id is already in the array, remove it
        updatedIds.splice(index, 1)
      } else {
        // If the id is not in the array, add it
        updatedIds.push(Number(id))
      }
      return { ...prevState, oemPositionStatementIds: updatedIds }
    })
  }

  const handleOemStatementClick = useCallback(async (event) => {
    const id = event.target.id
    setStatementLoading(id)
    try {
      await handleDownloadOEMStatementPDF(id)
    } catch (error) {
      Logger.error({ message: error, payload: { id, path: 'ClientInvoiceDocumentPage/handleOemStatementClick' } })
    } finally {
      setStatementLoading(false)
    }
  }, [])

  return (
    <AdasContainer maxWidth='lg'>
      {!loading && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem' }}>
          {workOrderData.reference_number && (
            <AdasBox sx={{ display: 'flow-root' }}>
              <span>
                <span style={{ float: 'left' }}>
                  <IdDisplay
                    itemId={workOrderData.reference_number}
                    roNumber={workOrderData.repair_order_num}
                    itemType={fromInvoice ? 'Invoice' : 'WO'}
                  />
                </span>
                <span style={{ float: 'right' }}>
                  <span>{vehicleName}</span>
                </span>
              </span>
            </AdasBox>
          )}
          <AdasBox>
            <h2>Pre Calibration Set Up</h2>
          </AdasBox>
          <AdasBox>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}> Dash Information</AdasTypography>
              <AdasBox sx={{ marginBottom: '20px' }}>
                <AdasTextField
                  onChange={handleFormInputChange}
                  id='outlined-basic'
                  name='odo_current'
                  label='Odometer *'
                  variant='outlined'
                  value={preCalInputValues.odo_current}
                  fullWidth={true}
                  placeholder='eg. 30000'
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: <AdasInputAdornment position='end'>MI</AdasInputAdornment>
                  }}
                  disabled={disableEdits}
                />
              </AdasBox>
              <AdasBox sx={{ marginBottom: '20px' }}>
                <PhotoInput {...generatePhotoInputProps(FieldNames.DASH_IMAGE_URL, 'ADD PHOTO OF DASH')} />
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          <AdasBox sx={{ marginTop: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>FUEL WEIGHT COMPENSATION</AdasTypography>
              <AdasBox sx={{ display: 'flow-root', marginBottom: '20px' }}>
                <AdasTypography sx={{ float: 'left', ...fontCss }}>Tank Capacity</AdasTypography>
                <AdasTypography sx={{ float: 'right', ...fontCss }}>{tankCapacity} gal</AdasTypography>
              </AdasBox>
              <AdasBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <AdasTypography sx={{ ...fontCss }}>Current Fuel Level {tankCapacity === 0 ? '' : '*'}</AdasTypography>
                <AdasFormControl sx={{ width: '40%' }}>
                  <AdasSearchMenu
                    id='fuel level'
                    select
                    label={tankCapacity !== 0 ? 'How full is the tank?' : 'No fuel tank'}
                    name='fuel_est_per'
                    value={preCalInputValues.fuel_est_per}
                    onChange={handleFuelLevel}
                    disabled={tankCapacity === 0 || disableEdits}
                    sx={{ backgroundColor: 'white' }}
                    options={FUEL_LEVEL_MAP}
                  />
                </AdasFormControl>
              </AdasBox>
              <AdasBox sx={{ display: 'flow-root', marginBottom: '20px' }}>
                <AdasTypography sx={{ float: 'left', ...fontCss }}>Weight Needed</AdasTypography>
                <AdasTypography sx={{ float: 'right', ...fontCss }}>{weightNeeded} lbs</AdasTypography>
              </AdasBox>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput
                    {...generatePhotoInputProps(FieldNames.WEIGHT_IMAGE_URL_1, 'ADD PHOTO OF WEIGHT (OPTIONAL FOR EV & FULL TANK)')}
                  />
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.WEIGHT_IMAGE_URL_2, 'ADD ADDITIONAL PHOTO (OPTIONAL)')} />
                </AdasBox>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <h3>Exterior Information</h3>
            <AdasTextField
              onChange={handleFormInputChange}
              disabled={disableEdits}
              name='vehicle_license'
              sx={{ marginBottom: '20px' }}
              id='license'
              label='License Plate # (Optional)'
              variant='outlined'
              value={preCalInputValues.vehicle_license}
              fullWidth={true}
              placeholder='eg. F3T H42'
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 10
              }}
            />
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>FOUR CORNERS</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_FRONT_IMAGE_URL, 'DRIVER SIDE FRONT CORNER')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_REAR_IMAGE_URL, 'DRIVER SIDE REAR CORNER')} />
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_FRONT_IMAGE_URL, 'PASSENGER SIDE FRONT CORNER')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_REAR_IMAGE_URL, 'PASSENGER SIDE REAR CORNER')} />
                </AdasBox>
              </AdasBox>
            </AdasPaper>

            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={{ ...classes.subHeading, paddingBottom: '0px' }}>TIRE PRESSURE</AdasTypography>
              <AdasTypography sx={classes.subHeading2}>{GENERIC_MESSAGES.TIRE_PRESSURE_CHECK}</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_FRONT_WHEEL_IMAGE_URL, 'DRIVER SIDE FRONT WHEEL')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_REAR_WHEEL_IMAGE_URL, 'DRIVER SIDE REAR WHEEL')} />
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_FRONT_WHEEL_IMAGE_URL, 'PASSENGER SIDE FRONT WHEEL')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_REAR_WHEEL_IMAGE_URL, 'PASSENGER SIDE REAR WHEEL')} />
                </AdasBox>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          <AdasBox sx={{ borderBottom: '1px solid black' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px' }}>
              <AdasTypography sx={classes.subHeading}>REPAIR ESTIMATE</AdasTypography>
              <AdasFormControl sx={{ paddingBottom: '20px' }} disabled={disableEdits}>
                <AdasFormLabel id='demo-controlled-radio-buttons-group' sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  Is there a collision repair estimate? *
                </AdasFormLabel>
                <AdasRadioGroup
                  row
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='isCollisionRepairEstimate'
                  value={preCalInputValues.isCollisionRepairEstimate}
                  onChange={handleFormInputChange}
                />
              </AdasFormControl>
              {preCalInputValues.isCollisionRepairEstimate && (
                <AdasBox>
                  <AdasTypography sx={classes.subHeading}>COLLISION REPAIR DOCUMENTS</AdasTypography>
                  <AdasFormGroup>
                    <AdasFormControlLabel
                      control={
                        <AdasCheckbox
                          checked={preCalInputValues.is_review_col_est}
                          onChange={handleFormInputChange}
                          name='is_review_col_est'
                          disabled={disableEdits}
                        />
                      }
                      label='Review collision repair estimate, understand what ADAS features have been affected (damaged, moved, removed, or replaced).'
                    />
                  </AdasFormGroup>
                  <FileUploadLongCard
                    removeFile={() => removeFileHandler('preCalRepairEstimateScan')}
                    existingData={dataUrls.preCalRepairEstimateScan}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_COLLISION_REPAIR_ESTIMATE}
                    label={FILE_UPLOAD.LABEL.COLLISION_REPAIR_ESTIMATE}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_REPAIR_ESTIMATE_SCAN}
                    disabled={disableEdits}
                  />
                  <AdasFormGroup>
                    <AdasFormControlLabel
                      control={
                        <AdasCheckbox
                          checked={preCalInputValues.is_cal_req_report_complete}
                          onChange={handleFormInputChange}
                          name='is_cal_req_report_complete'
                          disabled={disableEdits}
                        />
                      }
                      label='Complete Calibration(s) Required Report'
                    />
                  </AdasFormGroup>
                  <FileUploadLongCard
                    removeFile={() => removeFileHandler('calRequiredReport')}
                    existingData={dataUrls.calRequiredReport}
                    workOrderData={workOrderData}
                    title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REQUIRED_REPORT}
                    label={FILE_UPLOAD.LABEL.CALIBRATION_REQUIRED_REPORT}
                    typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REQUIRED_REPORT}
                    disabled={disableEdits}
                  />
                </AdasBox>
              )}
            </AdasPaper>
            <AdasFormGroup>
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={preCalInputValues.is_batt_support}
                    onChange={handleFormInputChange}
                    name='is_batt_support'
                    disabled={disableEdits}
                  />
                }
                label='Perform battery support *'
              />
            </AdasFormGroup>
            <AdasFormGroup>
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={preCalInputValues.is_rm_excess_cargo}
                    onChange={handleFormInputChange}
                    name='is_rm_excess_cargo'
                    disabled={disableEdits}
                  />
                }
                label='Check for and/or remove excessive cargo *'
              />
            </AdasFormGroup>
            <AdasFormGroup>
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={preCalInputValues.precal_scan}
                    onChange={handleFormInputChange}
                    name='precal_scan'
                    disabled={disableEdits}
                  />
                }
                label='Perform Pre Calibration Scan *'
              />
            </AdasFormGroup>
            <FileUploadLongCard
              removeFile={() => removeFileHandler('preCalReportScan')}
              existingData={dataUrls.preCalReportScan}
              workOrderData={workOrderData}
              title={FILE_UPLOAD.TITLE.UPLOAD_PRE_CALIBRATION_SCAN_REPORT}
              label={FILE_UPLOAD.LABEL.PRE_CALIBRATION_SCAN_REPORT}
              typeLabel={FILE_UPLOAD.TYPE_LABEL.PRE_CALIBRATION_SCAN_REPORT}
              disabled={disableEdits}
            />
            <AdasFormGroup>
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={preCalInputValues.is_wheel_align_complete}
                    onChange={handleFormInputChange}
                    name='is_wheel_align_complete'
                    disabled={disableEdits}
                  />
                }
                label='Complete Wheel Alignment Audit *'
              />
            </AdasFormGroup>
            <FileUploadLongCard
              removeFile={() => removeFileHandler('wheelAlignmentScan')}
              existingData={dataUrls.wheelAlignmentScan}
              workOrderData={workOrderData}
              title={FILE_UPLOAD.TITLE.UPLOAD_WHEEL_ALIGNMENT_AUDIT}
              label={FILE_UPLOAD.LABEL.WHEEL_ALIGNMENT_AUDIT}
              typeLabel={FILE_UPLOAD.TYPE_LABEL.WHEEL_ALIGNMENT_REPORT}
              disabled={disableEdits}
            />
            <AdasBox sx={{ display: 'flex', marginTop: '20px' }}>
              <AdasBox
                sx={{
                  marginRight: dataUrls[FieldNames.ALIGNMENT_IMAGE_URL]?.id || dataUrls[FieldNames.ALIGNMENT_IMAGE_URL_2]?.id ? '20px' : 0,
                  width: '100%'
                }}
              >
                <PhotoInput
                  {...generatePhotoInputProps(FieldNames.ALIGNMENT_IMAGE_URL, 'ADD PHOTO OF WHEEL ALIGNMENT (OPTIONAL)')}
                  allowDelete={true}
                />
              </AdasBox>

              {(dataUrls[FieldNames.ALIGNMENT_IMAGE_URL]?.id || dataUrls[FieldNames.ALIGNMENT_IMAGE_URL_2]?.id) && (
                <AdasBox sx={{ width: '100%' }}>
                  <PhotoInput
                    {...generatePhotoInputProps(FieldNames.ALIGNMENT_IMAGE_URL_2, 'ADD PHOTO OF WHEEL ALIGNMENT (OPTIONAL)')}
                    allowDelete={true}
                  />
                </AdasBox>
              )}
            </AdasBox>
            <AdasFormGroup>
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={preCalInputValues.is_oem_procedure_complete}
                    onChange={handleFormInputChange}
                    name='is_oem_procedure_complete'
                    disabled={disableEdits}
                  />
                }
                label='Review and add OEM Procedure *'
              />
            </AdasFormGroup>
            <FileUploadLongCard
              removeFile={() => removeFileHandler('oemProcedureScan')}
              existingData={dataUrls.oemProcedureScan}
              workOrderData={workOrderData}
              title={FILE_UPLOAD.TITLE.UPLOAD_OEM_PROCEDURE}
              label={FILE_UPLOAD.LABEL.OEM_PROCEDURE}
              typeLabel={FILE_UPLOAD.TYPE_LABEL.OEM_PROCEDURE}
              disabled={disableEdits}
            />
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px',
                minHeight: '150px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>Add OEM Position Statement</AdasTypography>
              <AdasFormControl fullWidth>
                <AdasInputLabel id='oem_position_id'>OEM Position Statement (Optional)</AdasInputLabel>
                <AdasSelect
                  fullWidth
                  id='oem_position_id'
                  select
                  label='OEM Position Statement (Optional)'
                  name='oem_position_id'
                  value={preCalInputValues.oem_position_id}
                  onChange={handleOEMPostionChange}
                  sx={{ backgroundColor: 'white' }}
                  disabled={disableEdits}
                  options={oemPositions}
                />
              </AdasFormControl>
              <AdasBox sx={{ display: 'flex', flexDirection: 'column', mt: '14px' }}>
                {oemPositionStatementList.map((data, index) => (
                  <AdasBox key={index} sx={{ display: 'flex', alignItems: 'center', mb: '10px' }}>
                    {statementLoading == data.id ? (
                      <CircularProgress size={20} sx={{ mr: '10px' }} />
                    ) : (
                      <AdasCheckbox
                        checked={preCalInputValues.oemPositionStatementIds.includes(data.id)}
                        label={data.name}
                        value={data.id}
                        sx={{ padding: '0', mr: '10px' }}
                        onChange={handleOemStatementSelection}
                      />
                    )}
                    <AdasLink
                      id={data.id}
                      sx={{
                        fontSize: '14px',
                        textDecoration: 'none',
                        color: '#0077cc',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={handleOemStatementClick}
                    >
                      {data.name}
                    </AdasLink>
                  </AdasBox>
                ))}
                {!!oemPositionStatementList.length && (
                  <AdasTypography sx={{ fontSize: '14px', ml: '5px', mb: '10px' }}>
                    {GENERIC_MESSAGES.OEM_STATEMENT_HELPER_MESSAGE}
                  </AdasTypography>
                )}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          <StickyFooter>
            <AdasBox
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'space-between',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                  alignItems: 'stretch'
                }
              }}
            >
              <AdasButton
                variant='outlined'
                aria-label='return to Summary'
                color='primary'
                onClick={returnToSummaryHandler}
                sx={{
                  '@media (max-width: 600px)': {
                    width: '100%'
                  }
                }}
              >
                {disableEdits ? '' : 'SAVE & '}RETURN TO SUMMARY
              </AdasButton>
              <AdasButton
                disabled={!isSubmitEnabled || fromInvoice}
                variant='contained'
                aria-label='Set up complete'
                color='primary'
                onClick={submit}
                sx={{
                  '@media (max-width: 600px)': {
                    width: '100%'
                  }
                }}
              >
                SET UP COMPLETE
              </AdasButton>
            </AdasBox>
          </StickyFooter>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}

PreCalibrationPage.propTypes = {
  fromInvoice: PropTypes.bool
}
