import PropTypes from 'prop-types'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import theme from 'theme/theme'
import { CurrencyInput } from '@components/currency-input'
import { AdasDrawer, AdasBox, AdasTypography, AdasButton, AdasPaper, AdasTextField, AdasButtonGroup } from '@components/wrapper-components'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export const EditProductPricing = ({ type, title, product, descriptionLength = 100, isOpen, onClose, handleInputChange, onSubmit }) => {
  const { itemNum, name, price, description, totalPrice, quantity } = product
  const isBtnDisabled = !price || !quantity
  const isEdit = product.id
  const [itemType, setItemType] = useState(product.item_type ? product.item_type : 'credit') // update type in FE when BE is done
  const [isValid, setIsValid] = useState({
    price: true,
    quantity: true
  })

  const handleClose = () => onClose(type)
  return (
    <AdasDrawer open={isOpen} anchor='right' onClose={handleClose}>
      <AdasBox className='drawer-title-container'>
        <AdasTypography variant='h6'>{title}</AdasTypography>
        <AdasButton
          buttonType='iconButton'
          sx={{
            padding: '2px',
            cursor: 'pointer',
            color: theme.palette.info.main
          }}
          onClick={handleClose}
        >
          <CloseIcon fontSize='small' />
        </AdasButton>
      </AdasBox>
      <AdasPaper sx={{ margin: '25px', p: '1rem' }}>
        {type === 'custom' ? (
          <AdasBox sx={{ mb: '20px' }}>
            <AdasTypography variant='overline' sx={{ fontWeight: '500', display: 'block' }}>
              ITEM TYPE
            </AdasTypography>
            <AdasButtonGroup variant='contained'>
              <AdasButton onClick={() => setItemType('credit')} variant={itemType === 'credit' ? 'contained' : 'outlined'}>
                CHARGE
              </AdasButton>
              <AdasButton onClick={() => setItemType('discount')} variant={itemType === 'discount' ? 'contained' : 'outlined'}>
                DISCOUNT
              </AdasButton>
            </AdasButtonGroup>
          </AdasBox>
        ) : (
          <AdasBox
            sx={{
              display: 'flex',
              marginRight: { xs: '.2rem' }
            }}
          >
            <AdasTypography sx={{ textTransform: 'uppercase', mb: '20px' }} variant='body2' color='secondary'>
              <span>{title} ID</span> #{itemNum}
            </AdasTypography>
          </AdasBox>
        )}
        <AdasTextField
          onChange={handleInputChange}
          disabled={true}
          name='name'
          sx={{ marginBottom: '20px' }}
          id='name'
          label='Name'
          variant='outlined'
          value={name}
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            maxLength: 80
          }}
        />
        {type === 'labour' ? (
          <>
            <CurrencyInput
              value={price} // update value depending on API
              error={!isValid.price} // update value depending on API
              label='Hourly Rate'
              id='hourlyRate'
              name='hourlyRate'
              onChange={handleInputChange}
              fullWidth={true}
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
              sx={{ marginBottom: '20px' }}
            />
            <AdasTextField
              onChange={handleInputChange}
              name='hours'
              sx={{ marginBottom: '20px' }}
              id='hours'
              label='Hours'
              variant='outlined'
              value={quantity} // update value depending on API
              error={!isValid.quantity} // update value depending on API
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          </>
        ) : (
          <>
            <CurrencyInput
              value={price}
              error={!isValid.price}
              label='Unit Price'
              id='unitPrice'
              name='unitPrice'
              onChange={handleInputChange}
              fullWidth={true}
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
              sx={{ marginBottom: '20px' }}
            />
            <AdasTextField
              onChange={handleInputChange}
              name='quantity'
              sx={{ marginBottom: '20px' }}
              id='quantity'
              label='Quantity'
              variant='outlined'
              value={quantity}
              error={!isValid.quantity}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          </>
        )}
        <CurrencyInput
          value={totalPrice}
          label='Total Price'
          id='totalPrice'
          name='totalPrice'
          onChange={handleInputChange}
          fullWidth={true}
          disabled={true}
          variant='outlined'
          InputLabelProps={{
            shrink: true
          }}
          sx={{ marginBottom: '20px' }}
        />
        <AdasTextField
          fullWidth
          name='description'
          label='Description'
          sx={{ marginBottom: '20px' }}
          value={description}
          onChange={handleInputChange}
          inputProps={{ maxLength: descriptionLength }}
          helperText={`Maximum ${descriptionLength} characters (${descriptionLength - (description?.length || 0)} remaining).`}
          multiline
          rows={4}
        />
        <AdasBox
          sx={{
            display: 'flex',
            justifyContent: type === 'custom' && isEdit ? 'space-between' : 'flex-end',
            alignItems: 'center'
          }}
        >
          {/* Render Delete button only for custom type and edit mode */}
          {type === 'custom' && isEdit && (
            <AdasButton color='error' startIcon={<DeleteOutlineIcon />} sx={{ mr: '8px' }} onClick={() => {}}>
              Delete
            </AdasButton>
          )}
          <AdasBox
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center'
            }}
          >
            <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={handleClose}>
              Cancel
            </AdasButton>
            <AdasButton type='submit' disabled={isBtnDisabled} variant='contained' aria-label='save' color='primary' onClick={onSubmit}>
              Save
            </AdasButton>
          </AdasBox>
        </AdasBox>
      </AdasPaper>
    </AdasDrawer>
  )
}

EditProductPricing.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    itemNum: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    description: PropTypes.string,
    totalPrice: PropTypes.number,
    quantity: PropTypes.number,
    item_type: PropTypes.string
  }).isRequired,
  descriptionLength: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}
