// pages/index.js
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'
import {
  AdasBox,
  AdasContainer,
  AdasPaper,
  AdasTypography,
  AdasButton,
  AdasGrid,
  AdasImage,
  AdasDrawer
} from '@components/wrapper-components'
import theme from 'theme/theme'
import { StickyFooter } from '@components/sticky-footer'
import { useHistory } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import DeleteIcon from '../../../assets/images/delete_icon.svg'
import CloseIcon from '@mui/icons-material/Close'
import AvailabilityForm from './AvailabilityForm'
import { useCenterStore, useOrganisationStore, useAvailabilityStore } from '@caradasstore'
import { getDateOverrides, deactivateDateOverridesByDate } from '../../../api/api'
import { GENERIC_MESSAGES } from 'constants'
import { Logger } from '../../../logger'
import { groupSlotsBySameDate, formatDate } from '../../../utils/utils'
import dayjs from 'dayjs'

const DateOverride = (props) => {
  const { date, slots = [], onTimeChange, index, onRemove } = props
  const formatTimeSlot = (time) => dayjs(time, 'HH:mm').format('h:mm A')

  return (
    <AdasGrid item display='flex' container xs={12} alignItems={{ xs: 'left', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }}>
      <AdasGrid xs={12} sm={4} sx={{ marginBottom: 1 }}>
        <AdasTypography variant='subtitle1'>{formatDate(date, 'MMMM D, YYYY')}</AdasTypography>
      </AdasGrid>
      <AdasGrid xs={12} sm={8} display='flex' alignItems={'center'} justifyContent={'space-between'}>
        <AdasGrid xs={8} sm={6} sx={{ marginBottom: 2 }}>
          {slots.length === 0 ? (
            <AdasTypography variant='subtitle1'>All Day (Unavailable)</AdasTypography>
          ) : (
            slots.map((slot, idx) => (
              <AdasTypography key={idx} variant='subtitle1'>
                {formatTimeSlot(slot.start)} to {formatTimeSlot(slot.end)}
                {'  (Unavailable)'}
              </AdasTypography>
            ))
          )}
        </AdasGrid>
        <AdasGrid xs={4} sm={6} sx={{ marginBottom: 2 }}>
          <AdasButton sx={{ float: 'right' }} color='primary' onClick={() => onRemove(index)}>
            <AdasImage src={DeleteIcon} alt='holder' sx={{ height: '24px' }} color='primary' />
          </AdasButton>
        </AdasGrid>
      </AdasGrid>
    </AdasGrid>
  )
}

const DateOverrides = () => {
  const [dates, setDates] = useState([])
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const [isAddDateOpen, setIsAddDateOpen] = useState(false)
  const ccId = useCenterStore((store) => store.ccId)
  const organisationId = useOrganisationStore((store) => store.organisationId)
  const setAvailabilityDetail = useAvailabilityStore((store) => store.setAvailability)
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const handleRemove = async (index) => {
    const dateToRemove = dates[index]?.overrides_date
    if (!dateToRemove) return

    try {
      await deactivateDateOverridesByDate({ ccId, organisation_id: organisationId, overrides_date: dateToRemove })
      await fetchDateOverrides()
      setIsSaveEnabled(true)
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'dateoverrides', method: 'handleRemove' }
      })
    }
  }

  const fetchDateOverrides = async () => {
    try {
      setLoading(true)
      const response = await getDateOverrides({ ccId, organisation_id: organisationId })
      if (response) {
        const dateWithSlots = groupSlotsBySameDate(response.data)
        setDates(dateWithSlots)
        setAvailabilityDetail(dateWithSlots)
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { file: 'dateoverrides', method: 'fetchDateOverrides' }
      })
    } finally {
      setLoading(false)
    }
  }

  const handleBackToCalendar = () => {
    history.push({
      pathname: '/scheduler'
    })
  }

  useEffect(() => {
    if ((ccId && organisationId) || !isAddDateOpen) {
      fetchDateOverrides()
    }
  }, [ccId, organisationId, isAddDateOpen])

  DateOverride.propTypes = {
    date: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onTimeChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  }
  return (
    <AdasContainer maxWidth='lg'>
      <AdasBox sx={{ minHeight: '75vh' }}>
        <AdasBox my={3} display='flex' gap={3} justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <AdasTypography fontSize='small' color={theme.typography.shaded}>
            {GENERIC_MESSAGES.DATE_OVERRIDES_MESSAGE}
          </AdasTypography>

          <AdasButton startIcon={<AddCircleOutline />} variant='outlined' color='primary' onClick={() => setIsAddDateOpen(true)}>
            ADD DATE
          </AdasButton>
        </AdasBox>
        <AdasBox container='true' direction='column' spacing={2} mt={2}>
          {loading ? ( // Display loading indicator while data is being fetched
            <AdasTypography variant='subtitle1' textAlign='center'>
              Loading date overrides...
            </AdasTypography>
          ) : dates.length === 0 ? (
            <AdasTypography variant='subtitle1' textAlign='center'>
              No date overrides available
            </AdasTypography>
          ) : (
            dates.map((date, index) => (
              <DateOverride
                key={index}
                index={index}
                date={date.overrides_date || ''}
                slots={date.slots || []}
                onTimeChange={() => {}}
                onRemove={handleRemove}
              />
            ))
          )}
        </AdasBox>
      </AdasBox>
      <StickyFooter sxProps={{ backgroundColor: '#ffff' }}>
        <Divider sx={{ marginY: '20px', marginX: '-40px' }} />
        <AdasBox>
          <AdasButton startIcon={<ArrowBackIosNewIcon />} onClick={handleBackToCalendar}>
            {' '}
            back to calendar
          </AdasButton>
        </AdasBox>
      </StickyFooter>
      {isAddDateOpen && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '20px' }}>
          <AdasDrawer anchor={'right'} open={isAddDateOpen} onClose={() => setIsAddDateOpen(false)}>
            <AdasBox className='drawer-title-container'>
              <AdasTypography variant='h6'>Add Date</AdasTypography>
              <AdasButton
                buttonType='iconButton'
                sx={{
                  padding: '2px',
                  cursor: 'pointer',
                  color: theme.palette.info.main
                }}
                onClick={() => setIsAddDateOpen(false)}
              >
                <CloseIcon fontSize='small' />
              </AdasButton>
            </AdasBox>
            <AvailabilityForm setIsAddDateOpen={setIsAddDateOpen} dates={dates} onSuccess={fetchDateOverrides} />
          </AdasDrawer>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}

export default DateOverrides
