import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: { main: '#0071E3' },
    secondary: { main: '#8A8A8A' },
    background: {
      default: '#FDFBFF',
      highlight: '#96F7B8',
      shaded: '#1A1B1F80',
      lightGray: '#E1E9F0',
      light: '#EFF7FF'
    },
    info: {
      main: '#001B3F',
      light: '#D7E2FF'
    },
    error: {
      main: '#E01A1A',
      light: '#FFDAD6'
    },
    success: {
      main: '#6FBF73'
    },
    fullScreenHeight: '100vh'
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 14,
    defaultColor: '#000000DE',
    secondaryColor: '#888d9a',
    territoryColor: '#212121',
    error: '#E01A1A',
    shaded: '#1a1b1fde',
    success: '#09AF00',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeight500: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300
    // allVariants: {
    //   fontSize: '14px', // Default font size for all variants
    //   '@media (max-width:600px)': {
    //     fontSize: '12px' // Change to 12px when screen size is xs or smaller
    //   }
    // }
  },
  padding: {
    smallPadding: '1rem',
    mediumPadding: '2rem',
    largePadding: '3rem',
    extraSmallPadding: '0.5rem',
    extraLargePadding: '4rem',
    doublePadding: '2rem 2rem'
  }
})

export default theme
