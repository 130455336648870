import CloseIcon from '@mui/icons-material/Close'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'

import { ProductForm } from '@components/product'
import { StatusInfo } from '@components/status-info'
import { AdasBox, AdasButton, AdasCard, AdasTypography } from '@components/wrapper-components'
import { useUserStore } from '../../../store'
import { currencyFormatter } from '../../../utils/utils'
import { productCategories } from '../product-categories/productCategories'
import theme from 'theme/theme'
import useBooleanStore from '@caradasstore/BooleanStore'

export const ProductInfo = ({ product, productTypeName, closeDrawer, onSaveSuccess }) => {
  const [updatedProductInfo, setUpdatedProductInfo] = useState(product)
  const [isProductEdit, setIsProductEdit] = useState(false)
  const user = useUserStore((store) => store.currentUser)
  const { isToggled } = useBooleanStore()
  const labelStyle = {
    margin: '.5rem 0 0 0',
    fontWeight: '600'
  }

  const isTechUser = useMemo(() => {
    if (user) {
      return !(user.is_admin || user.is_manager || user.is_owner)
    }
    return false
  }, [user])

  const onSaveProductSuccessHandler = (props) => {
    setUpdatedProductInfo(props)
    onSaveSuccess()
    setIsProductEdit(false)
  }

  return (
    <AdasBox>
      <AdasBox className='drawer-title-container'>
        <AdasTypography variant='h6' component='span' sx={{ textTransform: 'capitalize' }}>
          {productTypeName}
        </AdasTypography>
        <AdasButton
          buttonType='iconButton'
          sx={{
            padding: '2px',
            cursor: 'pointer',
            color: theme.palette.info.main
          }}
          onClick={closeDrawer}
        >
          <CloseIcon fontSize='small' />
        </AdasButton>
      </AdasBox>
      {!isProductEdit ? (
        <AdasCard className='adas-card'>
          <AdasTypography component='span'>
            <AdasTypography variant='overline'>
              {productTypeName} ID #{product.itemNum}
            </AdasTypography>
            <StatusInfo
              color={updatedProductInfo.is_active ? 'primary' : 'default'}
              label={updatedProductInfo?.is_active ? 'Active' : 'Inactive'}
              sx={{ float: 'right' }}
            />
          </AdasTypography>
          <AdasTypography sx={{ fontWeight: '600', marginTop: '1rem' }} variant='h6'>
            {updatedProductInfo.name}
          </AdasTypography>
          <AdasTypography>{updatedProductInfo.type}</AdasTypography>
          {updatedProductInfo.category && [
            <AdasTypography key={updatedProductInfo.category + '_label'} sx={labelStyle} variant='subtitle2'>
              Category
            </AdasTypography>,
            <AdasTypography key={updatedProductInfo.category}>{productCategories[updatedProductInfo.category]}</AdasTypography>
          ]}
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Price
          </AdasTypography>
          <AdasTypography>{currencyFormatter(updatedProductInfo.price)}</AdasTypography>
          {isToggled && (
            <>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Quantity
              </AdasTypography>
              <AdasTypography>{updatedProductInfo?.quantity || 'N/A'}</AdasTypography>
            </>
          )}
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Description
          </AdasTypography>
          <AdasTypography sx={{ mb: 0 }}>{updatedProductInfo.description}</AdasTypography>
          <AdasBox sx={{ textAlign: 'end', paddingTop: '1rem' }}>
            {!isTechUser && (
              <AdasButton
                startIcon={<ModeEditOutlineOutlinedIcon />}
                key='2'
                aria-label='Cancel'
                color='primary'
                onClick={() => setIsProductEdit(true)}
              >
                Edit
              </AdasButton>
            )}
          </AdasBox>
        </AdasCard>
      ) : (
        <ProductForm
          productData={updatedProductInfo}
          close={() => setIsProductEdit(false)}
          onSaveSuccess={onSaveProductSuccessHandler}
          productTypeName={productTypeName}
          edit={true}
        />
      )}
    </AdasBox>
  )
}

ProductInfo.propTypes = {
  product: PropTypes.object.isRequired,
  productTypeName: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired
}
