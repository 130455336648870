import { createSvgIcon } from '@mui/material/utils'

export const SuccessIcon = createSvgIcon(
  <svg width='50' height='50' viewBox='0 0 50 50' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='49' height='49' rx='24.5' fill='#0E73F1' />
    <rect x='0.5' y='0.5' width='49' height='49' rx='24.5' stroke='#0E73F1' />
    <path d='M31.6666 20L22.4999 29.1667L18.3333 25' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'SuccessIcon'
)
