import { useEffect, useRef } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

export const AdasTabs = ({ tabs, sx = {}, value, onChange, ...props }) => {
  const theme = useTheme()
  const defaultFontWeight = sx.fontWeight || theme.typography.fontWeight500
  const tabsRef = useRef()
  useEffect(() => {
    const selectedTab = tabsRef.current?.querySelector(`[role="tab"][aria-selected="true"]`)
    if (selectedTab) {
      selectedTab.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }, [value])
  return (
    <Tabs
      value={value}
      onChange={onChange}
      {...props}
      sx={{
        ...sx,
        padding: 0,
        '& .MuiTabs-scrollButtons': {
          width: 'auto'
        },
        '& .MuiTabs-scrollButtons.Mui-disabled': {
          display: 'none' // Remove space when the button is disabled
        }
      }}
      variant='scrollable'
      scrollButtons='auto'
      allowScrollButtonsMobile
    >
      {tabs.map((tab, index) => (
        <Tab
          sx={{
            fontWeight: defaultFontWeight,
            minWidth: 0, // Prevent large default width
            ...sx
          }}
          key={`${index}_tab`}
          label={tab.label}
          value={tab.value}
        />
      ))}
    </Tabs>
  )
}

AdasTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  sx: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func
}
