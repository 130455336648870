import { ClientInfo, ClientContactList } from '@components/client'
import { useParams } from 'react-router-dom'
import { AdasBox, AdasContainer, AdasPaper, AdasTabs } from '@components/wrapper-components'
import { PARAM_NAMES } from '../../constants'
import { useState } from 'react'

export const ClientDetailsPage = () => {
  const [settingType, setSettingType] = useState('client')
  const { [PARAM_NAMES.CLIENT_ID]: clientId } = useParams()
  const onTabChange = (e, value) => {
    setSettingType(value)
  }
  const tabs = [
    { label: 'CLIENT INFO', value: 'client' },
    { label: 'CONTACTS', value: 'contacts' }
  ]
  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        sx={{
          padding: '1.25rem 1.25rem',
          marginTop: '1.25rem',
          backgroundColor: 'white',
          marginBottom: '40px'
        }}
      >
        <AdasBox>
          <AdasTabs tabs={tabs} value={settingType} onChange={onTabChange} variant='scrollable' scrollButtons='auto' />
          {settingType === 'client' && <ClientInfo clientId={clientId} />}
          {settingType === 'contacts' && <ClientContactList clientId={clientId} />}
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
