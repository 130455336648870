import PropTypes from 'prop-types'
import { formatDate } from 'utils/utils'
import { AdasCard, AdasTypography, AdasBox } from '@components/wrapper-components'
import dayjs from 'dayjs'
import { DATE_FORMATS } from 'constants'
import { useDeviceQueries } from '@hooks/useDeviceQueries'
export const DataOverrideCard = ({ availabilities, ...props }) => {
  const { isMobile } = useDeviceQueries()

  const formatTimeSlot = (time) => dayjs(time, 'HH:mm').format('hh:mm A')
  const blockStyle = {
    width: '100%',
    borderRadius: '16px',
    padding: '16px 24px',
    border: '1px solid #c4c4c4',
    boxShadow: 'none',
    display: 'inline-block',
    marginBottom: '16px',
    backgroundColor: '#f5f8fa'
  }
  return (
    <AdasCard sx={blockStyle}>
      <AdasTypography variant='subtitle1' sx={{ fontWeight: 'bold', mb: 1 }}>
        DATE OVERRIDE
      </AdasTypography>
      <AdasTypography variant='subtitle1'>{formatDate(availabilities.overrides_date, DATE_FORMATS.ISO_DATE_STRING)}</AdasTypography>
      <AdasTypography variant='subtitle1'>Blocked Time Slots:</AdasTypography>
      <AdasBox mb={2}>
        <ul style={{ listStyleType: 'disc', paddingLeft: '1.75rem' }}>
          {availabilities.slots.length === 0 ? (
            <li>
              <AdasTypography variant='body2'>All Day Unavailable</AdasTypography>
            </li>
          ) : (
            availabilities.slots.map((availability, index) => (
              <li key={index}>
                <AdasTypography variant='body2' component='span'>
                  {formatTimeSlot(availability.start)} - {formatTimeSlot(availability.end)}
                </AdasTypography>
              </li>
            ))
          )}
        </ul>
      </AdasBox>
      <AdasTypography variant='subtitle1' sx={{ fontStyle: 'italic' }}>
        Managed by {isMobile && <br />} Calendar Preferences
      </AdasTypography>
    </AdasCard>
  )
}

DataOverrideCard.propTypes = {
  children: PropTypes.node
}
